import getStore from 'Util/Store';

/** @namespace FullyBooked/Util/Text/ucFirst */
export const ucFirst = (str) => (typeof str === 'string'
    ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '');

/** @namespace FullyBooked/Util/Text/capitalizeStr */
export const capitalizeStr = (str) => (str ? str.replace(/(?:_| |\b)(\w)/g, (str, p1) => p1.toUpperCase()) : '');

/** @namespace FullyBooked/Util/Text/translate */
export const translate = (str) => {
    const { CustomNotificationsReducer } = getStore().getState();
    const {
        messageList = {}
    } = CustomNotificationsReducer;

    return messageList[str] || str;
};

/** @namespace FullyBooked/Util/Text/toCamelCase */
export const toCamelCase = (string, prependIs) => {
    const stringTobeCamelCased = prependIs ? `is_${string}` : string;
    return string ? stringTobeCamelCased.toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase()) : string;
};

/**
 * validates high level ISBN 10/13 values
 * @returns bool
 * @namespace FullyBooked/Util/Text/validIsbn
 */
export const validIsbn = (isbnString) => (typeof isbnString === 'string'
    ? !!isbnString.match(/^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/gm) : false);
