import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import {
    EventsType,
    FieldAttrType
} from 'SourceType/Field.type';

import './KemanaPasswordField.scss';
/** @namespace KemanaPasswordField/Component/KemanaPasswordField/Component */
export class KemanaPasswordFieldComponent extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired
    };

    state = {
        isTogglePasswordActive: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct() {
        this.handlePasswordToggle = this.handlePasswordToggle.bind(this);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handlePasswordToggle(event) {
        event.preventDefault();
        const { isTogglePasswordActive } = this.state;
        this.setState({
            isTogglePasswordActive: !isTogglePasswordActive
        });
    }

    renderTogglePasswordButton() {
        return (
                <button
                  aria-label="Toggle Password"
                  onClick={ this.handlePasswordToggle }
                  block="FieldInput"
                  elem="TogglePassword"
                  type="button"
                />
        );
    }

    render() {
        const { isTogglePasswordActive } = this.state;
        const {
            setRef, attr, events, isDisabled

        } = this.props;

        return (
            <div block="FieldInput" elem="Password" mods={ { isTogglePasswordActive } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ isTogglePasswordActive ? FIELD_TYPE.text : FIELD_TYPE.password }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                />
                { this.renderTogglePasswordButton() }
            </div>
        );
    }
}

export default KemanaPasswordFieldComponent;
