import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import loadingIcon from '../../../public/images/loading-icon.gif';

/** @namespace FullyBooked/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                    <img src={ loadingIcon } alt="Loader" />
            </div>
        );
    }
}

export default LoaderComponent;
