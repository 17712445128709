import { FREE } from './CheckoutPayments.config';

/** @namespace KemanaFreePaymentMethod/Component/CheckoutPayments/Container/componentDidMount */
export const componentDidMount = (args, callback, instance) => {
    const { totals: { grand_total, subtotal } } = instance.props;
    if (subtotal !== 0 && grand_total === 0) {
        instance.selectPaymentMethod({ code: FREE });
    }

    return callback(...args);
};

/** @namespace KemanaFreePaymentMethod/Component/CheckoutPayments/Container/componentDidUpdate */
export const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const { totals: { grand_total, subtotal } } = instance.props;
    const { totals: { grand_total: prevGrandTotal } } = prevProps;
    if (subtotal !== 0 && grand_total === 0 && prevGrandTotal !== 0) {
        instance.selectPaymentMethod({ code: FREE });
    }
};

/** @namespace KemanaFreePaymentMethod/Component/CheckoutPayments/Container/containerProps */
export const containerProps = (args, callback, instance) => {
    const { totals } = instance.props;

    return {
        ...callback(...args),
        totals
    };
};

export default {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            containerProps,
            componentDidUpdate,
            componentDidMount
        }
    }
};
