import { showPopup } from 'Store/Popup/Popup.action';

import { MY_ACCOUNT_TRACK_SHIPPING_POPUP } from '../MyAccountTrackShipping/MyAccountTrackShipment.config';

/** @namespace KemanaTrackShipping/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Container/onTrackShipmentClick */
export const onTrackShipmentClick = (context) => {
    const { items: { number: shipmentNumber }, showShipmentTrackingPopup } = context.props;
    showShipmentTrackingPopup({ shipmentNumber, title: __('Track Shipment') });
};

/** @namespace KemanaTrackShipping/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Container/containerProps */
export const containerProps = (args, callback, context) => {
    const oldProps = callback.apply(context, args);
    return { ...oldProps, onTrackShipmentClick: () => onTrackShipmentClick(context) };
};

/** @namespace KemanaTrackShipping/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Container/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        showShipmentTrackingPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_TRACK_SHIPPING_POPUP, payload))
    };
};

export const kemanaTrackShippingMyAccountOrderItemsTableContainerOverrides = {
    'member-function': { containerProps }
};

export default kemanaTrackShippingMyAccountOrderItemsTableContainerOverrides;

export const kemanaTrackShippingMyAccountOrderItemsTableContainerDispatchOverrides = {
    function: mapDispatchToProps
};
