import InstaFeedReducer from '../store/InstaFeed/InstaFeed.reducer';

const _getStoreConfigFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'instagram_feed_enable',
    'instagram_feed_access_token'
];

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    InstaFeedReducer
});

export default {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
