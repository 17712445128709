import { Field as SourceField } from 'SourceComponent/Field/Field.component';

/** @namespace FullyBooked/Component/Field/Component */
export class FieldComponent extends SourceField {
    // Renders * for required fields
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } }>
                *
            </span>
        );
    }

    renderSubTitle() {
        const { addRequiredTag, subTitle } = this.props;

        if (addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="SubTitle">
                { subTitle }
            </span>
        );
    }

    /**
     * @override to add SubTitle
     */

    renderLabel() {
        const {
            addRequiredTag, type, label, attr: { name } = {}
        } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label
                  block="Field"
                  elem="Label"
                  htmlFor={ name || `input-${type}` }
                  mods={ { isRequired: addRequiredTag } }
                >
                    { label }
                    { this.renderSubTitle() }
                    { this.renderRequiredTag() }
                </label>
            </div>
        );
    }
}

export default FieldComponent;
