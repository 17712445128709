import Field from 'Component/Field';

import { toCapitalize } from '../../util/Text/index';
import { PICK_UP_AT_STORE } from './CheckoutDeliveryOptions.config';

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Component/renderPickUpAtStore */
export const renderPickUpAtStore = (instance) => () => {
    const { storeList, selectedPickupStoreId, updateSelectedPickupStoreId } = instance.props;

    const storeListSelectData = (storeList || [])
        .map(({ id, name }) => ({ id, label: toCapitalize(name), value: id }));

    return (
        <div>
            <Field
              id="pickup-store"
              name="pickup-store"
              type="select"
              attr={ {
                  value: selectedPickupStoreId,
                  name: 'pickup_store',
                  selectPlaceholder: __('-- Select branch --')
              } }
              label={ __('Select Pickup Store') }
              mix={ { block: 'PickupStore', elem: 'Select' } }
              options={ storeListSelectData }
              events={ { onChange: updateSelectedPickupStoreId } }
              validationRule={ { isRequired: true } }
              addRequiredTag
            />
        </div>
    );
};

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Component/shippingRenderMap */
export const shippingRenderMap = (originalMember, instance) => ({
    ...originalMember, [PICK_UP_AT_STORE]: renderPickUpAtStore(instance)
});

export default { 'Component/CheckoutDeliveryOptions/Component': { 'member-property': { shippingRenderMap } } };
