import { VALIDATION_INPUT_TYPE, VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES } from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';
export const PASSWORD_VALIDATION_RULES = {

    Uppercase: /[A-Z]/g,
    Lowercase: /[a-z]/g,
    Digits: /[0-9]/g,
    SpecialCharacters: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g
};
export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.email]: __('Please enter a valid email address (Ex: jdlcruz@domain.com).')
};
