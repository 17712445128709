import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { RefType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';
import { formatPrice } from 'Util/Price';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { giftCardAmountsType } from '../../type/GiftCardProduct.type';
import { getGiftCardFieldTypeByTitle } from '../../util/GiftCard';
import PRODUCT_TYPE, { OTHER_AMOUNT_TITLE } from './GiftCardProductOptions.config';

import './GiftCardProductOptions.style';

/** @namespace KemanaGiftCardProductType/Component/GiftCardProductOptions/Component */
export class GiftCardProductOptionsComponent extends PureComponent {
    state = {
        isOtherAmountSelected: false
    };

  static propTypes = {
      updateSelectedValues: PropTypes.func.isRequired,
      giftcard_amounts: giftCardAmountsType,
      product: ProductType.isRequired,
      configFormRef: RefType
  };

  static defaultProps = {
      configFormRef: createRef(),
      giftcard_amounts: []
  };

  renderAmountDropdown() {
      const {
          product: {
              giftcard_amounts = [], allow_open_amount,
              price_range: { minimum_price: { final_price: { currency } = {} } = {} } = {}
          }, updateSelectedValues
      } = this.props;

      const { isOtherAmountSelected } = this.state;

      const giftCardAmountOptions = giftcard_amounts.map(({ uid, value }) => ({
          id: uid, value: uid, label: value
      }));

      if (!giftCardAmountOptions.length) {
          return false;
      }

      const otherAmountTitleWithCurrency = `${OTHER_AMOUNT_TITLE} ${currency}`;
      const otherAmountOption = { id: 0, value: otherAmountTitleWithCurrency, label: __('Other Amount') };

      const withCustomGiftCardAmountOptions = [...giftCardAmountOptions, allow_open_amount && otherAmountOption];

      const title = __('Amount');
      const isRequired = true;

      const titleWithoutSpaces = title.split(' ').join('');
      const mods = { [titleWithoutSpaces]: true, isRequired, isOtherAmountSelected };

      return (
        <div block="GiftCardProductOptions" elem="FieldOuterWrapper" mods={ mods }>
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Amount') }
              events={ {
                  onChange: (value) => {
                      updateSelectedValues();
                      this.setState({ isOtherAmountSelected: value === otherAmountTitleWithCurrency });
                  }
              } }
              validationRule={ { isRequired } }
              options={ withCustomGiftCardAmountOptions.filter((option) => option) }
              attr={ { selectPlaceholder: __('-- Choose an Amount --') } }
              addRequiredTag
            />
        </div>
      );
  }

  renderMinMaxOpenAmount(title) {
      const {
          product:
          {
              open_amount_min,
              open_amount_max,
              price_range: { minimum_price: { final_price: { currency } } }
          }
      } = this.props;

      const otherAmountTitleWithCurrency = `${OTHER_AMOUNT_TITLE} ${currency}`;

      if (title !== otherAmountTitleWithCurrency) {
          return false;
      }

      const openMinAmount = formatPrice(open_amount_min, currency);
      const openMaxAmount = formatPrice(open_amount_max, currency);

      return (
        <div block="GiftCardProductOptions" elem="OpenAmounts">
        { `Minimum: ${openMinAmount} Maximum: ${openMaxAmount}` }
        </div>
      );
  }

  renderGiftCardOptions() {
      const {
          product:
          {
              gift_card_options = [], type_id,
              price_range: { minimum_price: { final_price: { currency } = {} } = {} } = {}
          },
          configFormRef, updateSelectedValues
      } = this.props;

      const { isOtherAmountSelected } = this.state;

      if (type_id !== PRODUCT_TYPE.giftcard) {
          return null;
      }

      return (
            <form ref={ configFormRef }>
                { this.renderAmountDropdown() }
                { gift_card_options.map(({ uid, title, required }) => {
                    const type = getGiftCardFieldTypeByTitle(title);
                    const titleWithoutSpaces = title.split(' ').join('');

                    const otherAmountTitleWithCurrency = `${OTHER_AMOUNT_TITLE} ${currency}`;
                    const isOtherAmount = title === otherAmountTitleWithCurrency;

                    if (isOtherAmount && !isOtherAmountSelected) {
                        return false;
                    }

                    const mods = { [titleWithoutSpaces]: true, isRequired: required };

                    return (
                        <div block="GiftCardProductOptions" elem="FieldOuterWrapper" mods={ mods }>
                        <Field
                          type={ type }
                          attr={ { name: uid } }
                          key={ uid }
                          label={ title }
                          events={ { onInput: updateSelectedValues } }
                          validationRule={ {
                              isRequired: required || isOtherAmount,
                              inputType: VALIDATION_INPUT_TYPE[type]
                          } }
                          addRequiredTag
                        />
                        { isOtherAmountSelected && this.renderMinMaxOpenAmount(title) }
                        </div>
                    );
                }) }

            </form>
      );
  }

  render() {
      return (
          this.renderGiftCardOptions()
      );
  }
}

export default GiftCardProductOptionsComponent;
