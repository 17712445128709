import { PaymentshippinglogoReducer } from '../store/PaymentShipping/PaymentShippingLogo.reducer';

export const PaymentShippingDispatcher = import('../store/PaymentShipping/PaymentShippingLogo.dispatcher');

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    PaymentshippinglogoReducer
});

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        paymentShippinImages: () => PaymentShippingDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getPaymentsShipping(dispatch)
        )
    };
};

const componentDidMount = (args, callback, instance) => {
    const { paymentShippinImages } = instance.props;
    callback.apply(instance, args);
    paymentShippinImages();
};

export const config = {

    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },

    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount
        }
    },

    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
