import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getFormattedTrackingHistory } from '../../util/KemanaTrackShipping';
import { MY_ACCOUNT_TRACK_SHIPPING_POPUP } from './MyAccountTrackShipment.config';
import MyAccountTrackShipping from './MyAccountTrackShipping.component';

/** @namespace KemanaTrackShipping/Component/MyAccountTrackShipping/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    shipmentNumber: state.PopupReducer.popupPayload[MY_ACCOUNT_TRACK_SHIPPING_POPUP]?.shipmentNumber
});

export const KemanaTrackShippingDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "kemanaTrackShippingDispatcher" */
    '../../store/KemanaTrackShipping/KemanaTrackShipping.dispatcher'
);

/** @namespace KemanaTrackShipping/Component/MyAccountTrackShipping/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestTrackingDetails: (shipmentNumber) => KemanaTrackShippingDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestTrackingDetails(dispatch, shipmentNumber)
    )
});

/** @namespace KemanaTrackShipping/Component/MyAccountTrackShipping/Container */
export class MyAccountTrackShippingContainer extends PureComponent {
    static propTypes = {
        shipmentNumber: PropTypes.string,
        requestTrackingDetails: PropTypes.func.isRequired
    };

    static defaultProps = {
        shipmentNumber: ''
    };

    state = {
        isLoading: true,
        shipmentMessage: '',
        shipmentSteps: []
    };

    componentDidUpdate(prevProps) {
        const { shipmentNumber } = this.props;
        const { shipmentNumber: prevShipmentNumber } = prevProps;
        if (shipmentNumber !== prevShipmentNumber && shipmentNumber) {
            this.getTrackingDetails();
        }
    }

    async getTrackingDetails() {
        const { shipmentNumber } = this.props;
        const { requestTrackingDetails } = this.props;
        try {
            this.setState({ isLoading: true });
            const {
                message: shipmentMessage,
                histories
            } = await requestTrackingDetails(shipmentNumber);

            const shipmentSteps = getFormattedTrackingHistory(histories || []);
            this.setState({ shipmentMessage, shipmentSteps });
        } catch (e) { /* Do nothing */
        } finally {
            this.setState({ isLoading: false });
        }
    }

    containerProps = () => {
        const { shipmentSteps, shipmentMessage, isLoading } = this.state;
        const { shipmentNumber } = this.props;
        return {
            shipmentMessage, shipmentSteps, shipmentNumber, isLoading
        };
    };

    render() {
        return (
            <MyAccountTrackShipping
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTrackShippingContainer);
