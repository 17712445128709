export const UPDATE_BLOG_DETAILS = 'UPDATE_BLOG_DETAILS';
export const UPDATE_BLOG_CATEGORY_LIST = 'UPDATE_BLOG_CATEGORY_LIST';
export const UPDATE_BLOG_TAG_LIST = 'UPDATE_BLOG_TAG_LIST';
export const UPDATE_RELATED_BLOG_STATE = 'UPDATE_RELATED_BLOG_STATE';
export const CLEAR_CURRENT_BLOG = 'CLEAR_CURRENT_BLOG';
export const UPDATE_BLOG_DATE_SORT_LIST = 'UPDATE_BLOG_DATE_SORT_LIST';
export const UPDATE_BLOG_PRODUCT_SKU_LIST = 'UPDATE_BLOG_PRODUCT_SKU_LIST';

/** @namespace BlogModule/Store/Blog/Action/clearCurrentBlog */
export const clearCurrentBlog = () => ({ type: CLEAR_CURRENT_BLOG });

/** @namespace BlogModule/Store/Blog/Action/updateBlogDetails */
export const updateBlogDetails = (blog) => ({
    type: UPDATE_BLOG_DETAILS,
    blog
});

/** @namespace BlogModule/Store/Blog/Action/setRelatedBlogState */
export const setRelatedBlogState = (newState) => ({
    type: UPDATE_RELATED_BLOG_STATE,
    newState
});

/** @namespace BlogModule/Store/Blog/Action/updateBlogCategoryList */
export const updateBlogCategoryList = (categoryList) => ({
    type: UPDATE_BLOG_CATEGORY_LIST,
    categoryList
});

/** @namespace BlogModule/Store/Blog/Action/updateBlogTagList */
export const updateBlogTagList = (tagList) => ({
    type: UPDATE_BLOG_TAG_LIST,
    tagList
});
/** @namespace BlogModule/Store/Blog/Action/updateBlogDateSortList */
export const updateBlogDateSortList = (dateSortList) => ({
    type: UPDATE_BLOG_DATE_SORT_LIST,
    dateSortList
});

/** @namespace BlogModule/Store/Blog/Action/updateBlogProductSkuList */
export const updateBlogProductSkuList = (productSkuList) => ({
    type: UPDATE_BLOG_PRODUCT_SKU_LIST,
    productSkuList
});
