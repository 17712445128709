export const PAYMENT_REDIRECT_TYPES = { URL: 'URL', FORM: 'FORM', CUSTOM: 'CUSTOM' };

export const PAYMENT_LIST = {
    PAYMAYA: 'paymaya_payment',
    DRAGONPAY: 'dragonpay',
    METRO_BANK: 'metro_bank',
    FREE: 'free'
};

export const SUPPORTED_PAYMENTS = {
    [PAYMENT_LIST.PAYMAYA]: {
        enabled: true,
        redirect: PAYMENT_REDIRECT_TYPES.URL
    },
    [PAYMENT_LIST.DRAGONPAY]: {
        enabled: true,
        redirect: PAYMENT_REDIRECT_TYPES.URL
    },
    [PAYMENT_LIST.METRO_BANK]: {
        enabled: true,
        redirect: PAYMENT_REDIRECT_TYPES.CUSTOM
    },
    [PAYMENT_LIST.FREE]: {
        enabled: true,
        redirect: PAYMENT_REDIRECT_TYPES.CUSTOM
    }
};

export const LAST_REDIRECT_ORDER = 'lastRedirectedOrder';

export const ORDER_TYPE = {
    PLACED: 'Order Placed',
    PROCESSING: 'Order Processing',
    PENDING: 'Order Pending',
    FAILED: 'Order Failed',
    SUCCESS: 'Order Success',
    INVALID: 'Order Invalid'
};

export const CHECKOUT_CALLBACK = 'checkout_callback';

export const CHECKOUT_PAYMENT_CALLBACK_URL = '/payments/callback';
