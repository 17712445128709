import { FREE } from './CheckoutPayments.config';

/** @namespace KemanaFreePaymentMethod/Component/CheckoutPayments/Component/renderFreePricePayment */
export const renderFreePricePayment = (instance) => {
    const paymentMethods = [{
        code: FREE,
        title: __('No Payment Information Required')
    }];

    return paymentMethods.map(instance.renderPayment);
};

/** @namespace KemanaFreePaymentMethod/Component/CheckoutPayments/Component/renderPayments */
export const renderPayments = (args, callback, instance) => {
    const { totals: { grand_total, subtotal } } = instance.props;
    if (subtotal !== 0 && grand_total === 0) {
        return renderFreePricePayment(instance);
    }

    return callback(...args);
};

export default { 'Component/CheckoutPayments/Component': { 'member-function': { renderPayments } } };
