import { getUniqueId } from 'kemana-return-orders/src/util/ReturnOrders';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import { getCancelOrderSubmitData } from '../../util/KemanaCancelOrders';
import MyAccountCancelOrderPopup from './MyAccountCancelOrderPopup.component';
import { MY_ACCOUNT_CANCEL_ORDER_POPUP, ORDER_PROCESSING_STATUS } from './MyAccountCancelOrderPopup.config';

export const KemanaCancelOrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "cancelOrderDispatcher" */
    '../../store/KemanaCancelOrders/KamanaCancelOrders.dispatcher'
);

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "orderDispatcher" */
    'Store/Order/Order.dispatcher'
);

/** @namespace KemanaCancelOrders/Component/MyAccountCancelOrderPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    orderId: state.PopupReducer.popupPayload[MY_ACCOUNT_CANCEL_ORDER_POPUP]?.orderId,
    orderStatus: state.PopupReducer.popupPayload[MY_ACCOUNT_CANCEL_ORDER_POPUP]?.orderStatus,
    orderProcessingStatuses: state.ConfigReducer.cancel_order_order_status,
    cancelOrderSubtitle: state.ConfigReducer.cancel_order_order_popup_notice,
    cancelOrderReasonsString: state.ConfigReducer.cancel_order_order_cancellation_reason,
    cancelOrderRequiredFieldsString: state.ConfigReducer.cancel_order_order_required_field
});
/** @namespace KemanaCancelOrders/Component/MyAccountCancelOrderPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    requestCancelOrder: (options) => KemanaCancelOrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCancelOrder({ ...options, dispatch })
    ),
    requestOrderDetails: (orderId) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)
    ),
    getOrderList: (page) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestOrders(dispatch, page)
    )
});

/** @namespace KemanaCancelOrders/Component/MyAccountCancelOrderPopup/Container */
export class MyAccountCancelOrderPopupContainer extends PureComponent {
    static propTypes = {
        orderId: PropTypes.string,
        orderStatus: PropTypes.string,
        hideActiveOverlay: PropTypes.func.isRequired,
        requestCancelOrder: PropTypes.func.isRequired,
        orderProcessingStatuses: PropTypes.string.isRequired,
        cancelOrderSubtitle: PropTypes.string.isRequired,
        cancelOrderReasonsString: PropTypes.string.isRequired,
        cancelOrderRequiredFieldsString: PropTypes.string.isRequired,
        requestOrderDetails: PropTypes.func.isRequired,
        getOrderList: PropTypes.func.isRequired
    };

    static defaultProps = {
        orderId: '',
        orderStatus: ''
    };

    state = {
        isCancelFormLoading: false,
        isLoading: false,
        cancelOrderDetails: { items: [] },
        cancelOrderItems: []
    };

    containerFunctions = {
        cancelOrder: this.cancelOrder.bind(this),
        addNewCancelOrderItem: this.addNewCancelOrderItem.bind(this)
    };

    componentDidUpdate(prevProps) {
        const isProcessingOrder = this.getIsProcessingOrder();
        const { orderId } = this.props;
        const { orderId: prevOrderId } = prevProps;
        if (orderId !== prevOrderId) {
            this.clearCancelOrderItems();
            if (isProcessingOrder) {
                this.getCancelOrderDetails();
            }
        }
    }

    clearCancelOrderItems() {
        this.setState({ cancelOrderItems: [] });
    }

    addNewCancelOrderItem() {
        const { cancelOrderDetails: { items } } = this.state;
        const { cancelOrderItems } = this.state;
        const newItem = { uuid: getUniqueId(), ...items[0] };
        this.setState({ cancelOrderItems: [...cancelOrderItems, newItem] });
    }

    removeCancelOrderItem = (index) => () => {
        // Need to set the timer here otherwise popup get closed due to closeOutside component
        setTimeout(() => {
            const { cancelOrderItems } = this.state;
            const newCancelOrderItems = Array.from(cancelOrderItems);
            newCancelOrderItems.splice(index, 1);
            this.setState({ cancelOrderItems: newCancelOrderItems });
        }, 0);
    };

    onChangeQuantity = (index) => ({ target: { value: quantity } }) => {
        const { cancelOrderItems } = this.state;
        const newCancelOrderItems = Array.from(cancelOrderItems);
        const quantityNumber = Number.isNaN(Number(quantity)) ? 0 : Number(quantity);
        newCancelOrderItems[index] = {
            ...newCancelOrderItems[index],
            remainingQuantity: Math.max(newCancelOrderItems[index].maxQuantity - quantityNumber, 0)
        };
        this.setState({ cancelOrderItems: newCancelOrderItems });
    };

    onChangeItem = (index) => (selectedItemId) => {
        const { cancelOrderItems, cancelOrderDetails: { items } } = this.state;
        const newCancelOrderItems = Array.from(cancelOrderItems);

        newCancelOrderItems[index] = {
            ...newCancelOrderItems[index],
            ...items.find((item) => item.value === selectedItemId),
            remainingQuantity: newCancelOrderItems[index].maxQuantity - 1
        };
        this.setState({ cancelOrderItems: newCancelOrderItems });
    };

    async getCancelOrderDetails() {
        const { orderId, requestOrderDetails } = this.props;
        this.setState({ isLoading: true });
        try {
            const cancelOrderDetails = await requestOrderDetails(orderId);
            const orderItems = cancelOrderDetails.items.map((item) => ({
                ...item,
                label: item.product_name,
                value: item.product_sku,
                maxQuantity: item.quantity_ordered,
                remainingQuantity: item.quantity_ordered
            }));

            this.setState({
                cancelOrderDetails: {
                    ...cancelOrderDetails, items: orderItems
                }
            });
            this.addNewCancelOrderItem();
        } catch (e) { /* Do Nothing */ } finally {
            this.setState({ isLoading: false });
        }
    }

    async cancelOrder(form, fields) {
        const {
            orderId, hideActiveOverlay, requestCancelOrder, getOrderList
        } = this.props;
        const cancelOrderOptions = {
            orderId: Number(orderId),
            ...getCancelOrderSubmitData(fields)
        };

        try {
            this.setState({ isCancelFormLoading: true });
            await requestCancelOrder(cancelOrderOptions);
            hideActiveOverlay();
        } catch (e) { /* Do nothing */
        } finally {
            this.setState({ isCancelFormLoading: false });
            getOrderList();
        }
    }

    getCancelOrderReasons() {
        const { cancelOrderReasonsString } = this.props;
        try {
            const returnData = [];
            JSON.parse(cancelOrderReasonsString).forEach((record) => {
                const key = Object.keys(record)[0];
                if (key) {
                    const reasonValue = record[key];
                    returnData.push({ value: reasonValue, label: reasonValue });
                }
            });

            return returnData;
        } catch (e) {
            return [];
        }
    }

    getCancelOrderRequiredFields() {
        const { cancelOrderRequiredFieldsString } = this.props;
        return (cancelOrderRequiredFieldsString || '').split(',');
    }

    getIsProcessingOrder = () => {
        const { orderStatus } = this.props;
        return ORDER_PROCESSING_STATUS.toLowerCase() === (orderStatus || '').toLowerCase();
    };

    containerProps = () => {
        const {
            isCancelFormLoading, isLoading, cancelOrderDetails, cancelOrderItems
        } = this.state;
        const { hideActiveOverlay, cancelOrderSubtitle } = this.props;

        return {
            isCancelFormLoading,
            isLoading,
            hideActiveOverlay,
            cancelOrderItems,
            cancelOrderSubtitle,
            cancelOrderDetails,
            removeCancelOrderItem: this.removeCancelOrderItem,
            onChangeItem: this.onChangeItem,
            onChangeQuantity: this.onChangeQuantity,
            isProcessingOrder: this.getIsProcessingOrder(),
            cancelOrderRequiredFields: this.getCancelOrderRequiredFields(),
            cancelOrderReasons: this.getCancelOrderReasons()
        };
    };

    render() {
        return (
            <MyAccountCancelOrderPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCancelOrderPopupContainer);
