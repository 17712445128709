import { showPopup } from 'Store/Popup/Popup.action';

import { verifyCancelOrderEnabledForCustomer } from '../../util/KemanaCancelOrders';
import { MY_ACCOUNT_CANCEL_ORDER_POPUP } from '../MyAccountCancelOrderPopup/MyAccountCancelOrderPopup.config';

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Container/onCancelClick */
export function onCancelClick(event) {
    const { showCancelOrderPopup, order: { id: orderId, status: orderStatus } } = this.props;
    event.stopPropagation();
    showCancelOrderPopup({ orderId, orderStatus, title: __('Cancel Order') });
}

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Container/containerFunctions */
export const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    onCancelClick: onCancelClick.bind(instance)
});

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        showCancelOrderPopup: (payload) => dispatch(showPopup(MY_ACCOUNT_CANCEL_ORDER_POPUP, payload))
    };
};

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Container/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        isCancelOrderEnabled: state.ConfigReducer.cancel_order_enable,
        cancelOrderCustomerGroups: state.ConfigReducer.cancel_order_customer_groups,
        cancelOrderButtonLabel: state.ConfigReducer.cancel_order_order_button_label,
        isCancelOrderPopupEnabled: state.ConfigReducer.cancel_order_order_enable_popup
    };
};

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Container/containerProps */
export const containerProps = (args, callback, context) => {
    const {
        isCancelOrderEnabled,
        cancelOrderCustomerGroups,
        cancelOrderButtonLabel,
        isCancelOrderPopupEnabled,
        order: orderItem
    } = context.props;

    return {
        ...callback(...args),
        cancelOrderButtonLabel,
        isCancelOrderEnabledForCustomer: verifyCancelOrderEnabledForCustomer({
            isCancelOrderEnabled,
            cancelOrderCustomerGroups,
            isCancelOrderPopupEnabled,
            orderItem
        })
    };
};

export const kemanaCancelOrderMyAccountOrderTableRowContainerOverrides = {
    'member-property': { containerFunctions },
    'member-function': { containerProps }
};

export const kemanaCancelOrderMyAccountOrderTableRowContainerDispatchOverrides = {
    function: mapDispatchToProps
};

export const kemanaCancelOrderMyAccountOrderTableRowContainerMapStateOverrides = {
    function: mapStateToProps
};
