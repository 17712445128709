export const UPDATE_GIFT_WRAPPING_DATA = 'UPDATE_GIFT_WRAPPING_DATA';
export const UPDATE_GIFT_WRAPPING_STATUS = 'UPDATE_GIFT_WRAPPING_STATUS';
export const UPDATE_GIFT_WRAPPING_LOADER = 'UPDATE_GIFT_WRAPPING_LOADER';
/** @namespace GiftWrapper/Store/GiftWrapper/Action/updateGiftWrapData */
export const updateGiftWrapData = (GiftWrapData) => ({
    type: UPDATE_GIFT_WRAPPING_DATA,
    GiftWrapData
});
/** @namespace GiftWrapper/Store/GiftWrapper/Action/updateGiftWrapStatus */
export const updateGiftWrapStatus = (GiftWrapStatus) => ({
    type: UPDATE_GIFT_WRAPPING_STATUS,
    GiftWrapStatus
});
/** @namespace GiftWrapper/Store/GiftWrapper/Action/updateGiftWrapLoader */
export const updateGiftWrapLoader = (GiftWrapLoader) => ({
    type: UPDATE_GIFT_WRAPPING_LOADER,
    GiftWrapLoader
});
