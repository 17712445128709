/** @namespace KemanaReturnOrders/Component/MyAccountOrderTotals/Container/getColSpanCounts */
import { colSpanCounts, colSpanCountsMobile } from './MyAccountOrderTotals.config';

/** @namespace KemanaReturnOrders/Component/MyAccountOrderTotals/Container/getColSpanCounts */
export const getColSpanCounts = (args) => {
    const { isMobile, activeTab } = args;
    if (isMobile) {
        const defaultSpanCount = {
            colSpanPriceCount: '2',
            colSpanLabelCount: '3'
        };

        return colSpanCountsMobile[activeTab] ?? defaultSpanCount;
    }

    const defaultSpanCount = {
        colSpanPriceCount: '1',
        colSpanLabelCount: '4'
    };

    return colSpanCounts[activeTab] ?? defaultSpanCount;
};

export const myAccountOrderTotalsContainerOverrides = {
    'member-function': { getColSpanCounts }
};

export default myAccountOrderTotalsContainerOverrides;
