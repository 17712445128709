/** @namespace KemanaEvents/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps */
import { updateSelectedPickupStoreId } from '../../store/Checkout/Checkout.action';

/** @namespace KemanaEvents/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps */
export const LogisticReducer = import('../../store/Logistic/Logistic.reducer');

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        getStoreList: (districtId) => LogisticReducer.then(
            /** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps/LogisticReducer/then */
            ({ default: dispatcher }) => dispatcher.getStoreList(districtId, dispatch)
        ),
        updateSelectedPickupStoreId: (id) => dispatch(updateSelectedPickupStoreId(id))
    };
};

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Container/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        storeList: state.CheckoutReducer.storeList,
        selectedPickupStoreId: state.CheckoutReducer.selectedPickupStoreId
    };
};

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Container/containerProps */
export const containerProps = (args, callback, instance) => {
    const {
        storeList,
        selectedPickupStoreId,
        updateSelectedPickupStoreId
    } = instance.props;

    return {
        ...callback(),
        storeList,
        selectedPickupStoreId,
        updateSelectedPickupStoreId
    };
};

/** @namespace KemanaStorePickupPlugin/Component/CheckoutDeliveryOptions/Container/componentDidMount */
export const componentDidMount = (args, callback, instance) => {
    const { getStoreList } = instance.props;
    getStoreList();
    callback();
};

export default {
    'Component/CheckoutDeliveryOptions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutDeliveryOptions/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutDeliveryOptions/Container': {
        'member-function': {
            containerProps,
            componentDidMount
        }
    }
};
