import { ORDER_REFUNDS } from '@scandipwa/scandipwa/src/component/MyAccountOrder/MyAccountOrder.config';

import Image from 'Component/Image';
import Link from 'Component/Link/Link.component';

import { ORDER_RETURNS } from '../MyAcccountOrder/MyAccountOrder.config';
import { MY_RETURN_URL } from '../MyAccount/MyAccount.config';

import './MyAccountOrderItemsTableRow.component.scss';

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderDesktopTableRow */
export const renderDesktopTableRow = (args, callback, context) => {
    const {
        activeTab, product: {
            number, created_at, shipFrom, fe_status, uid
        }
    } = context.props;

    if (activeTab === ORDER_RETURNS) {
        return (
            <div
              block="KeyValueTable"
              elem="Row"
              key={ uid }
            >
                <div block="KeyValueTable" elem="Column">{ number }</div>
                <div block="KeyValueTable" elem="Column">{ created_at }</div>
                <div block="KeyValueTable" elem="Column">{ shipFrom }</div>
                <div block="KeyValueTable" elem="Column">{ fe_status }</div>
                <div block="KeyValueTable" elem="Column">
                    <Link to={ `${MY_RETURN_URL}/${uid}` }>
                        { __('View Return') }
                    </Link>
                </div>
            </div>
        );
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderDiscountAndRowTotal */
export const renderDiscountAndRowTotal = (args, callback, context) => {
    const { activeTab } = context.props;

    if (activeTab === ORDER_REFUNDS) {
        return null;
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderRefundProductContent */
export const renderRefundProductContent = (context) => {
    const { product: { product_name, order_item: { product_thumbnail: { label, url } = {} } = {} } } = context.props;
    return (
        <div
          block="MyAccountOrderItemsTableRow"
          elem="NameAndOptions"
        >
            <span
              block="MyAccountOrderItemsTableRow"
              elem="ProductImage"
            >
                <Image
                  alt={ label }
                  src={ url }
                />
            </span>
            <span
              block="MyAccountOrderItemsTableRow"
              elem="Name"
            >
                { product_name }
            </span>
            { context.renderSelectedAndEnteredOptions() }
        </div>
    );
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderNameAndOptions */
export const renderNameAndOptions = (args, callback, context) => {
    const { activeTab } = context.props;

    if (activeTab === ORDER_REFUNDS) {
        return (
            <div
              block="KeyValueTable"
              elem="Column"
              mix={ { block: 'MyAccountOrderItemsTable', elem: 'Name' } }
            >
                { renderRefundProductContent(context) }
            </div>
        );
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderReturnMobileItems */
export const renderReturnMobileItems = (mobileItems) => (
    <>
    { mobileItems.map(({ label, value }) => (
        <div
          block="MyAccountOrderItemsTableRow"
          elem="Row"
          mix={ { block: 'KeyValueTable', elem: 'Row' } }
        >
          <div
            block="KeyValueTable"
            elem="Column"
          >
              <strong>{ label }</strong>
              { value }
          </div>
        </div>
    )) }
    </>
);

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderMobileTableRow */
export const renderMobileTableRow = (args, callback, context) => {
    const {
        activeTab, product: {
            number, created_at, shipFrom, fe_status, uid
        }
    } = context.props;

    if (activeTab === ORDER_RETURNS) {
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const mobileItems = [
            { label: __('Return #'), value: number },
            { label: __('Date'), value: created_at },
            { label: __('Ship From'), value: shipFrom },
            { label: __('Return Status'), value: fe_status },
            { label: '', value: <Link to={ `${MY_RETURN_URL}/${uid}` }>{ __('View Return') }</Link> }
        ];

        return (
            <div
              block="MyAccountOrderItemsTableRow"
              elem="Row"
              mix={ { block: 'KeyValueTable', elem: 'Row' } }
            >
                { renderReturnMobileItems(mobileItems) }
            </div>
        );
    } if (activeTab === ORDER_REFUNDS) {
        const {
            product: {
                row_subtotal: { value: subTotal } = {},
                quantity_refunded,
                product_sku, product_sale_price: { value: price } = {}
            }
        } = context.props;
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const mobileItems = [
            { label: __('SKU'), value: product_sku },
            { label: __('Price'), value: price },
            { label: __('Qty'), value: quantity_refunded },
            { label: __('Subtotal'), value: subTotal }
        ];

        return (
            <div
              block="KeyValueTable"
              elem="Content"
              mix={ { block: 'KeyValueTable', elem: 'MobileTable' } }
            >
            <div
              block="MyAccountOrderItemsTableRow"
              elem="Row"
              mix={ { block: 'KeyValueTable', elem: 'Row' } }
            >
                <div
                  block="KeyValueTable"
                  elem="Column"
                >
                    { renderRefundProductContent(context) }
                </div>
            </div>
            { renderReturnMobileItems(mobileItems) }
            </div>
        );
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow/Component/renderTableRow */
export const renderTableRow = (args, callback, context) => {
    const { isMobile } = context.props;
    if (isMobile) {
        return renderMobileTableRow(args, callback, context);
    }
    if (!isMobile) {
        return renderDesktopTableRow(args, callback, context);
    }

    return callback.apply(context, args);
};

export const myAccountOrderItemsTableRowComponentOverrides = {
    'member-function': {
        renderTableRow,
        renderDiscountAndRowTotal,
        renderNameAndOptions
    }
};

export default myAccountOrderItemsTableRowComponentOverrides;
