import FIELD_TYPE from 'Component/Field/Field.config';
import { getFormattedDate } from 'Util/Orders/Orders';

import {
    RETURN_CONDITION_LABEL,
    RETURN_RESOLUTION_LABEL
} from '../component/MyAccountCreateReturn/MyAccountCreateReturn.config';

/** @namespace KemanaReturnOrders/Util/ReturnOrders/formatReturnOrders */
export const formatReturnOrders = (orders) => orders.reduceRight((acc, order) => {
    const { created_at, customer: { firstname, lastname } = {} } = order;
    const formattedDate = getFormattedDate(created_at);
    const shipFrom = `${firstname} ${lastname}`;

    return [
        ...acc,
        {
            ...order,
            created_at: formattedDate,
            shipFrom
        }
    ];
}, []);

/** @namespace KemanaReturnOrders/Util/ReturnOrders/formatCreateReturnOrderObj */
export const formatCreateReturnOrderObj = (createReturnOrderObj) => {
    const { items_eligible_for_return } = createReturnOrderObj;
    const items = items_eligible_for_return
        .filter(({ eligible_for_return }) => eligible_for_return)
        .map(
            (item) => ({
                ...item,
                maxQuantity: item.quantity_ordered,
                label: item.product_name,
                value: item.id
            })
        );

    return { ...createReturnOrderObj, items };
};

/** @namespace KemanaReturnOrders/Util/ReturnOrders/formatReturnOrder */
export const formatReturnOrder = (returnOrder) => {
    const { created_at, items: returnItems } = returnOrder;
    const formattedDate = getFormattedDate(created_at);
    const [year, month, date] = formattedDate.split('-');
    const formattedDateWithSlash = [month, date, year].join('/');

    const items = returnItems.map(({
        uid: id,
        request_quantity: req_qty,
        quantity: qty,
        status,
        order_item: { product_name, product_sku }, custom_attributes
    }) => {
        const condition = custom_attributes
            .find(({ label }) => label === RETURN_CONDITION_LABEL)?.value?.replace(/"/g, '') || '';
        const resolution = custom_attributes
            .find(({ label }) => label === RETURN_RESOLUTION_LABEL)?.value?.replace(/"/g, '') || '';

        return {
            id, product_name, product_sku, condition, resolution, req_qty, qty, status
        };
    });

    return {
        ...returnOrder, created_at: formattedDateWithSlash, items
    };
};

/** @namespace KemanaReturnOrders/Util/ReturnOrders/getCreateReturnOrderSubmitData */
export const getCreateReturnOrderSubmitData = (fields) => {
    const returnOrderData = { items: [{ selected_custom_attributes: [], entered_custom_attributes: [] }] };
    fields.forEach(({
        name, type, value, field
    }) => {
        if (name === 'contact_email' || name === 'comment_text') {
            returnOrderData[name] = value;
        } else {
            const index = Number(field.getAttribute('data-index'));
            if (!returnOrderData.items[index]) {
                returnOrderData.items[index] = { selected_custom_attributes: [], entered_custom_attributes: [] };
            }
            if (name === 'quantity_to_return') {
                returnOrderData.items[index][name] = Number(value);
            } else if (name === 'order_item_uid') {
                returnOrderData.items[index][name] = value;
            } else if (name.indexOf('attr_') > -1) {
                const attributeKey = type === FIELD_TYPE.select ? 'selected' : 'entered';
                returnOrderData.items[index][`${attributeKey}_custom_attributes`].push({
                    attribute_code: name.replace('attr_', ''), value
                });
            }
        }
    });

    return returnOrderData;
};

/** @namespace KemanaReturnOrders/Util/ReturnOrders/getUniqueId */
// eslint-disable-next-line no-magic-numbers
export const getUniqueId = () => `id${Math.random().toString(16).slice(2)}`;
