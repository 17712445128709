import { CloseIcon as SourceCloseIcon } from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace FullyBooked/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIcon {
    render() {
        return (
            <div block="CloseIcon" />
        );
    }
}

export default CloseIconComponent;
