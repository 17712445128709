import { lazy } from 'react';

import Loader from 'Component/Loader';

import { FreeGiftsReducer } from '../store/FreeGifts/FreeGifts.reducer';

export const FreeGifts = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cart-gifts" */
    '../component/FreeGifts'
));

export const FreeGiftsPopup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cart-gifts" */
    '../component/FreeGiftPopup'
));
const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    FreeGiftsReducer
});

const renderFreeGifts = (props) => {
    const { totals: { id } } = props;
    if (id) {
        return (

            <div>
                <FreeGifts cartId={ id } />
            <FreeGiftsPopup cartId={ id } />
            </div>
        );
    }

    return null;
};
const renderHeading = (args, callback, instance) => (
    <>
    { callback.apply(instance, args) }
    { renderFreeGifts(instance.props) }
    </>
);

const mapStateToProps = (args = [], callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        isFreeGiftLoading: state.FreeGiftsReducer.FreeGiftLoader
    };
};
const renderFreeGiftLoader = (props) => {
    const { isFreeGiftLoading = false } = props;
    return (<Loader isLoading={ isFreeGiftLoading } />);
};

const renderCartItems = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        { renderFreeGiftLoader(instance.props) }
    </>
);

export const containerProps = (args, callback, instance) => {
    const { isFreeGiftLoader } = instance.props;

    return {
        ...callback(...args),
        isFreeGiftLoader
    };
};
export const config = {
    'Route/CartPage/Component': {
        'member-function': { // <- member-function means, the function is in the class
            renderHeading,
            renderCartItems
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            containerProps
        }
    },
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

export default config;
