import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace KemanaProductViewReport/Query/KemanaProductViewReport/Query */
export class KemanaProductViewReportQuery extends SourceProductListQuery {
    getProductViewReportQuery(id) {
        return new Field('productViewReport')
            .addArgument('input', 'ViewProductInput!', {
                product_id: id
            });
    }
}

export default new KemanaProductViewReportQuery();
