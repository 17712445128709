import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';

export const PaymentCallbackPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "kemanaPaymentMethods" */
    '../route/PaymentCallbackPage'
));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component:
            <Route
              path={ withStoreRegex('/payments/:callback') }
              exact
                // eslint-disable-next-line react/jsx-no-bind
              render={ (props) => (
                    <PaymentCallbackPage
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...props }
                    />
              ) }
            />,
        position: 101
    }
];

export default {

    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
