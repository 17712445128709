/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { OrderType } from 'Type/Order.type';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import {
    CANCEL_ORDER_FIELDS,
    COMMENT_VALIDATION,
    MY_ACCOUNT_CANCEL_ORDER_POPUP
} from './MyAccountCancelOrderPopup.config';

import './MyAccountCancelOrderPopup.style';

/** @namespace KemanaCancelOrders/Component/MyAccountCancelOrderPopup/Component */
export class MyAccountCancelOrderPopupComponent extends PureComponent {
    static propTypes = {
        isCancelFormLoading: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        cancelOrder: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isProcessingOrder: PropTypes.bool.isRequired,
        cancelOrderSubtitle: PropTypes.string.isRequired,
        cancelOrderDetails: OrderType.isRequired,
        onChangeItem: PropTypes.func.isRequired,
        onChangeQuantity: PropTypes.func.isRequired,
        removeCancelOrderItem: PropTypes.func.isRequired,
        cancelOrderReasons: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })).isRequired,
        addNewCancelOrderItem: PropTypes.func.isRequired,
        cancelOrderItems: PropTypes.arrayOf(
            PropTypes.shape({
                uuid: PropTypes.string,
                maxQuantity: PropTypes.number,
                remainingQuantity: PropTypes.number
            })
        ).isRequired,
        cancelOrderRequiredFields: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    renderSubTitle() {
        const { cancelOrderSubtitle } = this.props;
        return (
            <div
              block="MyAccountCancelOrderPopup"
              elem="Subtitle"
            >
                <p>{ cancelOrderSubtitle }</p>
            </div>
        );
    }

    renderItemFields(item, index) {
        const {
            cancelOrderDetails: { items },
            onChangeItem,
            onChangeQuantity,
            cancelOrderItems,
            removeCancelOrderItem
        } = this.props;

        return (
            <div block="MyAccountCancelOrderPopup" elem="ItemFields" key={ item.uuid }>
                <div block="MyAccountCancelOrderPopup" elem="SelectItemContainer">
                    <Field
                      type={ FIELD_TYPE.select }
                      label={ __('Item') }
                      events={ { onChange: onChangeItem(index) } }
                      attr={ {
                          name: CANCEL_ORDER_FIELDS.ITEM_SKU,
                          'data-field': 'order_item_uid',
                          defaultValue: 1,
                          noPlaceholder: true,
                          'data-index': index
                      } }
                      validationRule={ {
                          isRequired: true,
                          match: (value) => {
                              const fields = document.querySelectorAll('[data-field=order_item_uid]');
                              return Array.from(fields)
                                  .filter((field) => field.value === value).length === 1;
                          },
                          customErrorMessages: {
                              onMatchFail: __('Product already used, please select another product!')
                          }
                      } }
                      validateOn={ ['onChange'] }
                      addRequiredTag
                      options={ items }
                      changeValueOnDoubleClick
                    />
                </div>
                <Field
                  type={ FIELD_TYPE.input }
                  label={ __('Quantity to Cancel') }
                  events={ { onChange: onChangeQuantity(index) } }
                  attr={ {
                      placeholder: __('Enter Item Quantity'),
                      name: CANCEL_ORDER_FIELDS.ITEM_QTY,
                      'data-index': index
                  } }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.numeric,
                      range: {
                          min: 1,
                          max: cancelOrderItems[index].maxQuantity
                      }
                  } }
                  validateOn={ ['onChange'] }
                  addRequiredTag
                  options={ items }
                  changeValueOnDoubleClick
                />
                <div block="MyAccountCancelOrderPopup" elem="RemainingQty">
                    { __('Remaining Quantity: %s', cancelOrderItems[index].remainingQuantity) }
                </div>
                { index > 0 && (
                    <button
                      type="button"
                      block="MyAccountCancelOrderPopup"
                      elem="DeleteButton"
                      onClick={ removeCancelOrderItem(index) }
                      mix={ {
                          block: 'Button',
                          mods: { isHollow: true }
                      } }
                    >
                        { __('Delete') }
                    </button>
                ) }
            </div>
        );
    }

    renderItemsFields() {
        const { addNewCancelOrderItem, cancelOrderItems, cancelOrderDetails: { items } } = this.props;

        return (
            <div block="MyAccountCancelOrderPopup" elem="ReturnItemsFields">
                { cancelOrderItems.map((item, index) => this.renderItemFields(item, index)) }
                <button
                  block="Button"
                  type="button"
                  disabled={ cancelOrderItems.length === items.length }
                  onClick={ addNewCancelOrderItem }
                  mix={ { block: 'Button', mods: { Secondary: true } } }
                >
                    { __('ADD ANOTHER ITEM') }
                </button>
            </div>
        );
    }

    renderFormFields() {
        const { isProcessingOrder, cancelOrderReasons, cancelOrderRequiredFields } = this.props;
        const isReasonRequired = cancelOrderRequiredFields.indexOf(CANCEL_ORDER_FIELDS.REASON) > -1;
        return (
            <>
                <Field
                  type={ FIELD_TYPE.select }
                  label={ __('Reason for Cancellation Request') }
                  attr={ {
                      name: CANCEL_ORDER_FIELDS.REASON,
                      selectPlaceholder: __('-- Select cancellation reason --')
                  } }
                  validationRule={ { isRequired: isReasonRequired } }
                  validateOn={ ['onChange'] }
                  addRequiredTag={ isReasonRequired }
                  options={ cancelOrderReasons }
                  changeValueOnDoubleClick
                />
                { isProcessingOrder && this.renderItemsFields() }
                <Field
                  type={ FIELD_TYPE.textarea }
                  validationRule={ { ...COMMENT_VALIDATION } }
                  label={ __('Leave a comment') }
                  attr={ {
                      placeholder: __('Enter your comments here'),
                      name: CANCEL_ORDER_FIELDS.COMMENT
                  } }
                  validateOn={ ['onChange'] }
                />
            </>
        );
    }

    renderForm() {
        const { cancelOrder, isCancelFormLoading, hideActiveOverlay } = this.props;

        return (
            <Form
              block="MyAccountCancelOrderPopup"
              elem="CancelForm"
              onSubmit={ cancelOrder }
            >
                { this.renderFormFields() }
                <Loader isLoading={ isCancelFormLoading } />

                <div
                  block="MyAccountCancelOrderPopup"
                  elem="ButtonWrap"
                >
                <button
                  block="Button"
                  type="button"
                  onClick={ hideActiveOverlay }
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                >
                    { __('Cancel') }
                </button>

                <button
                  block="Button"
                  type="submit"
                  mix={ { block: 'MyAccountCancelOrderPopup', elem: 'SubmitButton' } }
                >
                    { __('Submit') }
                </button>
                </div>
            </Form>
        );
    }

    renderContent() {
        const { isLoading } = this.props;
        if (isLoading) {
            return null;
        }

        return (
            <>
                { this.renderSubTitle() }
                { this.renderForm() }
            </>
        );
    }

    renderPopupContent() {
        const { isLoading } = this.props;
        return (
            <div
              block="MyAccountCancelOrderPopup"
              elem="Container"
            >
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }

    render() {
        return (
            <Popup
              mix={ { block: 'MyAccountCancelOrderPopup' } }
              id={ MY_ACCOUNT_CANCEL_ORDER_POPUP }
              clickOutside
            >
                { this.renderPopupContent() }
            </Popup>
        );
    }
}

export default MyAccountCancelOrderPopupComponent;
