import { lazy } from 'react';

import routerComponent from '../component/Router/Router.component';
import myAccountRouteComponent from '../route/MyAccount/MyAccount.component';
import myAccountRouteContainer from '../route/MyAccount/MyAccount.container';

export const DiscountCard = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "my-account" */
    '../component/DiscountCard'
));

const renderDiscountCardComponent = () => (<DiscountCard isDashbord />);
const renderDiscountCard = (args, callback, instance) => (
    <>
    { callback.apply(instance, args) }
    { renderDiscountCardComponent() }
    </>
);
const config = {
    ...routerComponent,
    ...myAccountRouteContainer,
    ...myAccountRouteComponent,
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            renderDiscountCard
        }
    }

};

export default config;
