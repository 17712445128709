import { getInitialState as sourceGetInitialState } from 'Store/Checkout/Checkout.reducer.js';

import {
    UPDATE_PICKUP_STORE_LIST,
    UPDATE_SELECTED_PICKUP_STORE_ID
} from './Checkout.action';

/** @namespace KemanaStorePickupPlugin/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    storeList: [],
    selectedPickupStoreId: false
});

/** @namespace KemanaStorePickupPlugin/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (args, callback) => {
    const [state = getInitialState(), action] = args;
    switch (action.type) {
    case UPDATE_PICKUP_STORE_LIST:
        const { storeList } = action;
        return {
            ...state,
            storeList
        };

    case UPDATE_SELECTED_PICKUP_STORE_ID:
        const { selectedPickupStoreId } = action;
        return {
            ...state,
            selectedPickupStoreId
        };

    default:
        return callback(...args);
    }
};

export default {
    'Store/Checkout/Reducer/CheckoutReducer': {
        function: CheckoutReducer
    }
};
