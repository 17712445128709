import {
    getStaticReducers as sourceGetStaticReducers
} from 'SourceStore/index';
import CurrentStepReducer from 'Store/CurrentStep/CurrentStep.reducer';
import CustomNotificationsReducer from 'Store/CustomNotifications/CustomNotifications.reducer';

/**
 * Extended to add LocationChangerReducer
 * @extends
 * @namespace FullyBooked/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    CustomNotificationsReducer,
    CurrentStepReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
