import CheckoutQuery from 'Query/Checkout.query';
import {
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
import { injectScript } from 'Util/InjectScript';
import { fetchMutation } from 'Util/Request';

import googleTagManager from '../../../kemana-google-tag-manager/src/util/Google/googleTagManager';
import {
    CHECKOUT_CALLBACK, CHECKOUT_PAYMENT_CALLBACK_URL, LAST_REDIRECT_ORDER, ORDER_TYPE, PAYMENT_LIST,
    PAYMENT_REDIRECT_TYPES, SUPPORTED_PAYMENTS
} from './KemanaPaymentMethods.config';

const setOrderRef = (orderID, paymentType, type, customer) => {
    BrowserDatabase.setItem({
        orderID, paymentType, type, customer
    }, LAST_REDIRECT_ORDER);
};

const redirectingToGateway = (orderID, redirectUrl = false, paymentType, instance, customer) => {
    const { resetCart, resetGuestCart, totals } = instance.props;
    googleTagManager.purchaseDataAnalytics(totals, orderID);
    if (!isSignedIn()) {
        deleteGuestQuoteId();
    }

    BrowserDatabase.deleteItem(PAYMENT_TOTALS);
    const reset = isSignedIn() ? resetCart : resetGuestCart;
    caches.delete('graphql');
    reset().finally(() => {
        if (redirectUrl) {
            BrowserDatabase.deleteItem(CART_TOTALS);
            setOrderRef(orderID, paymentType, ORDER_TYPE.PLACED, customer);
            window.location.href = redirectUrl;
        }
    });
};

const triggerMetroBankPaymentRedirection = (id, name, merchant, invoiceNumber, paymentCode, instance, customer) => {
    window.Checkout.configure({
        session: { id },
        merchant,
        interaction: {
            merchant: { name },
            displayControl: {
                customerEmail: 'HIDE',
                billingAddress: 'HIDE',
                paymentTerms: 'HIDE',
                shipping: 'HIDE'
            }
        },
        order: { description: 'Payment on fullybookedonline.com', invoiceNumber }
    });

    setOrderRef(invoiceNumber, paymentCode, ORDER_TYPE.PLACED, customer);
    window.Checkout.showPaymentPage();
    BrowserDatabase.deleteItem(CART_TOTALS);
    instance.setState({ isLoading: false });
};

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { savedEmail } = instance.props;
    const { billing_address: { firstname, lastname } } = paymentInformation;
    const customer = {
        firstName: firstname, lastName: lastname, email: savedEmail
    };

    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;

    const supportedCode = SUPPORTED_PAYMENTS?.[code];

    if (supportedCode?.enabled) {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id, code));
            const {
                placeOrder:
                {
                    order: {
                        order_id, [`${code}_redirect_url`]: redirect_url,
                        extraFields: { name: merchantName = false, merchant_id: merchantId } = {}
                    }
                }
            } = orderData;

            if (
                code === PAYMENT_LIST.METRO_BANK
                 && supportedCode?.redirect === PAYMENT_REDIRECT_TYPES.CUSTOM) {
                triggerMetroBankPaymentRedirection(
                    redirect_url, merchantName, merchantId, order_id, code, instance, customer
                );
            } else
            if (
                code === PAYMENT_LIST.FREE
                 && supportedCode?.redirect === PAYMENT_REDIRECT_TYPES.CUSTOM) {
                setOrderRef(order_id, code, ORDER_TYPE.PLACED, customer);
                window.location.href = CHECKOUT_PAYMENT_CALLBACK_URL;
            } else {
                redirectingToGateway(order_id, redirect_url, code, instance, customer);
            }
        } catch (error) {
            const { showErrorNotification } = instance.props;
            const { message = '', debugMessage } = error?.length ? error[0] : error;
            showErrorNotification(debugMessage || message);
        }
    } else {
        callback.apply(instance, args);
    }
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showErrorNotification: (message) => dispatch(showNotification('error', message))
    };
};

const stateMap = (originalMember) => ({
    ...originalMember,
    [CHECKOUT_CALLBACK]: {
        title: true,
        back: false
    }
});

const checkoutComponentDidMount = async (args, callback, instance) => {
    callback.apply(instance, args);
    if (SUPPORTED_PAYMENTS[PAYMENT_LIST.METRO_BANK]?.enabled) {
        injectScript({ url: 'https://ap-gateway.mastercard.com/static/checkout/checkout.min.js' });
    }
};

export default {

    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            componentDidMount: checkoutComponentDidMount
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap
        }
    }
};
