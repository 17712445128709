/* eslint-disable react/jsx-no-bind */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';

import { BLOG_BASE } from '../component/BlogList/BlogList.config';
import { BlogReducer } from '../store/Blog/Blog.reducer';

export const BlogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ '../route/BlogPage'));
// eslint-disable-next-line max-len
export const BlogsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ '../route/BlogsPage'));

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    BlogReducer
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/blog/month/:monthUrlKey') } render={ (props) => <BlogsPage { ...props } /> } />,
        position: 83
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/blog/tag/:tagUrlKey') } render={ (props) => <BlogsPage { ...props } /> } />,
        position: 84
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/blog/category/:categoryUrlKey') } render={ (props) => <BlogsPage { ...props } /> } />,
        position: 85
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`/${BLOG_BASE}/:blogUrlKey`) } render={ (props) => <BlogPage { ...props } /> } />,
        position: 86
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/blog') } render={ (props) => <BlogsPage { ...props } /> } />,
        position: 87
    }
];

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
