import { addYears, getMonth, getYear } from 'date-fns';
import PropTypes from 'prop-types';
import { forwardRef, PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import {
    EventsType,
    FieldAttrType
} from 'Type/Field.type';

import {
    DATE_PICKER_FORMAT,
    DATE_PICKER_LIST_OF_MONTHS,
    DATE_PICKER_MIN_ACCEPTED_YEAR,
    DATE_PICKER_RANGE_START_YEAR
} from './KemanaDatePicker.config';

import './KemanaDatePicker.style';

/** @namespace KemanaDatePicker/Component/KemanaDatePicker/Component */
export class KemanaDatePickerComponent extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        value: PropTypes.string
    };

    static defaultProps = {
        onClick: () => {},
        value: ''
    };

    state = {
        selectedDate: ''
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const {
            attr: { defaultValue }
        } = this.props;

        if (defaultValue) {
            this.setState({ selectedDate: new Date(defaultValue) });
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleDateChange(selectedDate) {
        this.setState({ selectedDate });
    }

    renderCustomHeader() {
        const numberOfYears = new Date()
            .getFullYear() - (DATE_PICKER_MIN_ACCEPTED_YEAR - 1) - DATE_PICKER_RANGE_START_YEAR;
        const years = Array(numberOfYears).fill().map((_, key) => DATE_PICKER_RANGE_START_YEAR + key);

        return ({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }) => (
            <>
        <div
          block="FieldDate"
          elem="HeaderWrapperTop"
        >
          <button
            block="FieldDate"
            elem="BackwardButton"
            type="button"
            onClick={ decreaseMonth }
            disabled={ prevMonthButtonDisabled }
          >
            <span>{ '<' }</span>
          </button>
          <div
            block="FieldDate"
            elem="MonthandYear"
          >
            { `${DATE_PICKER_LIST_OF_MONTHS[getMonth(date)]} ${getYear(date)}` }
          </div>
          <button
            block="FieldDate"
            elem="ForwardButton"
            type="button"
            onClick={ increaseMonth }
            disabled={ nextMonthButtonDisabled }
          >
            <span>{ '>' }</span>
          </button>
        </div>
        <div
          block="FieldDate"
          elem="HeaderWrapperBottom"
        >
          <div
            block="FieldDate"
            elem="Month"
          >
            <select
              value={ DATE_PICKER_LIST_OF_MONTHS[getMonth(date)] }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={ (
                  { target: { value } }
              ) => changeMonth(DATE_PICKER_LIST_OF_MONTHS.indexOf(value)) }
            >
              { DATE_PICKER_LIST_OF_MONTHS.map((option) => (
                <option key={ option } value={ option }>
                  { option }
                </option>
              )) }
            </select>
          </div>
          <div
            block="FieldDate"
            elem="Year"
          >
            <select
              value={ getYear(date) }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={ ({ target: { value } }) => changeYear(value) }
            >
              { years.map((option) => (
                <option key={ option } value={ option }>
                  { option }
                </option>
              )) }
            </select>
          </div>
        </div>
            </>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    triggerDefaultEvents() {
        const { events } = this.props;
        Object.values(events).forEach((event) => event());
    }

    render() {
        const { selectedDate } = this.state;
        const {
            setRef, attr, events, isDisabled
        } = this.props;

        const { defaultValue, placeholder, ...restAttr } = attr;
        const KemanaDatePickerCustomInput = forwardRef(({ onClick, value }, _) => (
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ FIELD_TYPE.text }
                  placeholder={ placeholder }
                  value={ value || defaultValue }
                  readOnly
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...restAttr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  onClick={ onClick }
                />
        ));

        return (
            <div block="KemanaDatePicker">
                <DatePicker
                  selected={ selectedDate }
                  dateFormat={ DATE_PICKER_FORMAT }
                  customInput={ <KemanaDatePickerCustomInput /> }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ this.handleDateChange.bind(this) }
                  // eslint-disable-next-line react/jsx-no-bind
                  onCalendarClose={ this.triggerDefaultEvents.bind(this) }
                  renderCustomHeader={ this.renderCustomHeader() }
                  maxDate={ addYears(new Date(), DATE_PICKER_MIN_ACCEPTED_YEAR * -1) }
                />
            </div>
        );
    }
}

export default KemanaDatePickerComponent;
