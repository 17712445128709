import {
    UPDATE_GIFT_WRAPPING_DATA,
    UPDATE_GIFT_WRAPPING_LOADER,
    UPDATE_GIFT_WRAPPING_STATUS
} from './GiftWrapper.action';

/** @namespace GiftWrapper/Store/GiftWrapper/Reducer/getInitialState */
export const getInitialState = () => ({
    GiftWrapData: [],
    GiftWrapStatus: []
});

/** @namespace GiftWrapper/Store/GiftWrapper/Reducer/GiftWrapReducer */
export const GiftWrapReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        GiftWrapData,
        GiftWrapStatus,
        GiftWrapLoader = false
    } = action;

    switch (type) {
    case UPDATE_GIFT_WRAPPING_DATA:
        return {
            ...state,
            GiftWrapData
        };
    case UPDATE_GIFT_WRAPPING_STATUS:
        return {
            ...state,
            GiftWrapStatus
        };
    case UPDATE_GIFT_WRAPPING_LOADER:
        return {
            ...state,
            GiftWrapLoader
        };

    default:
        return state;
    }
};

export default GiftWrapReducer;
