import { verifyCancelOrderEnabledForCustomer } from '../../util/KemanaCancelOrders';

/** @namespace KemanaCancelOrders/Component/MyAccountMyOrders/Container/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        isCancelOrderEnabled: state.ConfigReducer.cancel_order_enable,
        cancelOrderCustomerGroups: state.ConfigReducer.cancel_order_customer_groups,
        isCancelOrderPopupEnabled: state.ConfigReducer.cancel_order_order_enable_popup
    };
};

/** @namespace KemanaCancelOrders/Component/MyAccountMyOrders/Container/containerProps */
export const containerProps = (args, callback, context) => {
    const {
        isCancelOrderEnabled,
        cancelOrderCustomerGroups,
        isCancelOrderPopupEnabled,
        orderList: { items: orderItems }
    } = context.props;

    return {
        ...callback(...args),
        isCancelOrderEnabledForCustomer: verifyCancelOrderEnabledForCustomer({
            isCancelOrderEnabled,
            cancelOrderCustomerGroups,
            isCancelOrderPopupEnabled,
            orderItems
        })
    };
};

export const kemanaCancelOrderMyAccountOrdersContainerOverrides = {
    'member-function': { containerProps }
};

export const kemanaCancelOrderMyAccountOrdersContainerMapStateOverrides = {
    function: mapStateToProps
};
