import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CartItemType } from 'Type/MiniCart.type';

import { giftcardProductOptionsType } from '../../type/GiftCardProduct.type';

/** @namespace KemanaGiftCardProductType/Component/GiftCardProductCartItemOptions/Component */
export class GiftCardProductCartItemOptionsComponent extends PureComponent {
  static propTypes = {
      item: PropTypes.oneOfType([giftcardProductOptionsType, CartItemType]).isRequired
  };

  renderOptions(sender, sender_email, reciepient, reciepient_email) {
      const hasSenderData = sender && sender_email;
      const hasReciepientData = reciepient && reciepient_email;

      return (
      <div block="CartItem" elem="Options">
        { hasSenderData && (
          <div block="CartItem" elem="Option">
            { `Sender: ${sender}<${sender_email}>` }
          </div>
        ) }
        { hasReciepientData && (
          <div block="CartItem" elem="Option">
            { `Recipient: ${reciepient}<${reciepient_email}>` }
          </div>
        ) }
      </div>
      );
  }

  render() {
      const {
          item: {
              giftcard_product_options: {
                  sender, sender_email, reciepient, reciepient_email
              }
          } = {}
      } = this.props;

      return this.renderOptions(sender, sender_email, reciepient, reciepient_email);
  }
}

export default GiftCardProductCartItemOptionsComponent;
