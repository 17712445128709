export const UPDATE_FREE_GIFTS = 'UPDATE_FREE_GIFTS';
export const UPDATE_FREE_GIFT_LOADER = 'UPDATE_FREE_GIFT_LOADER';

/** @namespace KemanaFreeGifts/Store/FreeGifts/Action/updateFreeGifts */
export const updateFreeGifts = (FreeGifts) => ({
    type: UPDATE_FREE_GIFTS,
    FreeGifts
});
/** @namespace KemanaFreeGifts/Store/FreeGifts/Action/updateFreeGiftLoader */
export const updateFreeGiftLoader = (FreeGiftLoader) => ({
    type: UPDATE_FREE_GIFT_LOADER,
    FreeGiftLoader
});
