import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';

import { ZEND_DESK_CHAT_TRIGGER } from './Html.config';
/** @namespace FullyBooked/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    /**
     * Replacing element with a button to trigger zendesk
     * Element identified if name attribute matches to @var ZEND_DESK_CHAT_TRIGGER
     */

    renderZendeskChatTrigger() {
        return (
            <button
              block="Button"
              mods={ { likeLink: true } }
              name="zEChatTrigger"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => window?.zE?.activate() }
            >
                <span>{ __('Live Chat') }</span>
            </button>
        );
    }

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            const { name: attrName } = domAttrs || {};

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return false;
            }

            // Replacing ZenDesk Chat trigger

            if (attrName === ZEND_DESK_CHAT_TRIGGER) {
                return this.renderZendeskChatTrigger();
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }

            return false;
        }
    };
}

export default HtmlComponent;
