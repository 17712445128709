import { AddIcon as SourceAddIcon } from 'SourceComponent/AddIcon/AddIcon.component';

/** @namespace FullyBooked/Component/AddIcon/Component */
export class AddIconComponent extends SourceAddIcon {
    render() {
        return (
            <div />
        );
    }
}

export default AddIconComponent;
