import { formatCreateReturnOrderObj, formatReturnOrder, formatReturnOrders } from '../../util/ReturnOrders';
import {
    GET_CREATE_RETURN_ORDER_DETAIL,
    GET_RETURN_ORDER_DETAIL,
    GET_RETURN_ORDER_LIST, SET_CREATE_RETURN_ORDER_DETAIL_LOADING_STATUS,
    SET_NEW_COMMENT_IN_RETURN_DETAILS,
    SET_RETURN_ORDER_DETAIL_LOADING_STATUS,
    SET_RETURN_ORDER_LOADING_STATUS
} from './ReturnOrder.action';

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    returnOrderList: {},
    isLoading: true,
    returnOrderDetail: {},
    isOrderDetailLoading: true,
    createReturnOrderDetail: {},
    isCreateOrderDetailLoading: true
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Reducer/ReturnOrderReducer */
export const ReturnOrderReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        orderList,
        loadingStatus,
        isOrderDetailLoading,
        returnOrderDetail,
        createReturnOrderDetail,
        isCreateOrderDetailLoading,
        comments
    } = action;

    switch (type) {
    case GET_RETURN_ORDER_LIST:
        const { items = [], page_info } = orderList;
        const formattedReturnOrders = formatReturnOrders(items);

        return {
            ...state,
            returnOrderList: { items: formattedReturnOrders, pageInfo: page_info }
        };

    case SET_RETURN_ORDER_LOADING_STATUS:
        return { ...state, isLoading: loadingStatus };

    case GET_RETURN_ORDER_DETAIL:
        return { ...state, returnOrderDetail: formatReturnOrder(returnOrderDetail) };

    case SET_RETURN_ORDER_DETAIL_LOADING_STATUS:
        return { ...state, isOrderDetailLoading };

    case SET_NEW_COMMENT_IN_RETURN_DETAILS:
        const { returnOrderDetail: stateReturnOrderDetail } = state;
        return { ...state, returnOrderDetail: { ...stateReturnOrderDetail, comments } };

    case GET_CREATE_RETURN_ORDER_DETAIL:
        return {
            ...state,
            createReturnOrderDetail: formatCreateReturnOrderObj(createReturnOrderDetail)
        };

    case SET_CREATE_RETURN_ORDER_DETAIL_LOADING_STATUS:
        return { ...state, isCreateOrderDetailLoading };

    default:
        return state;
    }
};

export default ReturnOrderReducer;
