import { connect } from 'react-redux';

import { HOME } from 'Route/HomePage/HomePage.config';
import {
    InstallPromptContainer as SourceInstallPromptContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/InstallPrompt/InstallPrompt.container';

/** @namespace FullyBooked/Component/InstallPrompt/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentStep: state.CurrentStepReducer.step
});

/** @namespace FullyBooked/Component/InstallPrompt/Container */
export class InstallPromptContainer extends SourceInstallPromptContainer {
    render() {
        const { currentStep } = this.props;
        return currentStep === HOME ? super.render() : false;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallPromptContainer);
