/** @namespace KemanaAccountStoreCredit/Route/MyAccount/Container/tabMap */
import { THIRD_SECTION } from 'Type/Account.type';

import { MY_ACCOUNT_GIFT_CARD_ROUTE } from '../../component/MyAccountGiftCard/MyAccountGiftCard.config';
import { MY_ACCOUNT_STORE_CREDIT_ROUTE } from '../../component/MyAccountStoreCredit/MyAccountStoreCredit.config';
import { MY_ACCOUNT_GIFT_CARD, MY_ACCOUNT_STORE_CREDIT } from '../../type/Account.type';

/** @namespace KemanaAccountStoreCredit/Route/MyAccount/Container/tabMap */
export const tabMap = (prevTabMap) => ({
    ...prevTabMap,
    [MY_ACCOUNT_STORE_CREDIT]: {
        url: MY_ACCOUNT_STORE_CREDIT_ROUTE,
        isFullUrl: true,
        tabName: __('My Store Credit'),
        section: THIRD_SECTION
    },
    [MY_ACCOUNT_GIFT_CARD]: {
        url: MY_ACCOUNT_GIFT_CARD_ROUTE,
        isFullUrl: true,
        tabName: __('Gift Card'),
        section: THIRD_SECTION
    }
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};
