import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';

import { STORE_LOCATOR } from '../component/StoreLocator/StoreLocator.config';
import GoogleMapReducer from '../store/GoogleMap/GoogleMap.reducer';

export const StoreLocator = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "store-locator" */
    '../route/StoreLocator'
));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
           <Route
             path={ withStoreRegex('/store-locator') }
             // eslint-disable-next-line react/jsx-no-bind
             render={ (props) => (
             <StoreLocator
               // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               { ...props }
             />
             ) }
           />
        ),
        position: 100
    }
];

const stateMap = (originalMember) => ({
    [STORE_LOCATOR]: {
        title: true,
        back: true
    },
    ...originalMember
});

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    GoogleMapReducer
});

const _getStoreConfigFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'kslocator_locator_api',
    'kslocator_general_url',
    'kslocator_general_meta_title',
    'kslocator_general_meta_description',
    'kslocator_locator_linktext',
    'kslocator_locator_zoom',
    'kslocator_locator_distance',
    'kslocator_locator_template',
    'kslocator_locator_close_text',
    'kslocator_locator_open_text',
    'kslocator_locator_show_distance'
];

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    },
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap
        }
    }
};

export default config;
