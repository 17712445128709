/** @namespace KemanaCancelOrders/Util/KemanaCancelOrders/Index/verifyCancelOrderEnabledForCustomer */
import BrowserDatabase from 'Util/BrowserDatabase';

import { CANCEL_ORDER_FIELDS } from '../../component/MyAccountCancelOrderPopup/MyAccountCancelOrderPopup.config';

/** @namespace KemanaCancelOrders/Util/KemanaCancelOrders/Index/verifyCancelOrderEnabledForCustomer */
export const verifyCancelOrderEnabledForCustomer = ({
    isCancelOrderEnabled,
    cancelOrderCustomerGroups,
    isCancelOrderPopupEnabled,
    orderItems,
    orderItem
}) => {
    if (!isCancelOrderEnabled) {
        return false;
    }
    if (!isCancelOrderPopupEnabled) {
        return false;
    }

    // Check for the order list header
    if (orderItems) {
        const isAllOrdersNotCancellable = orderItems
            .filter(({ is_cancelable }) => !is_cancelable)
            .length === orderItems.length;

        if (isAllOrdersNotCancellable) {
            return false;
        }
    }

    // Check for the order item
    if (orderItem) {
        const { is_cancelable } = orderItem;

        if (!is_cancelable) {
            return false;
        }
    }

    const customerGroupId = String(BrowserDatabase.getItem('customer').group_id);
    const cancelOrderCustomerGroupsArray = (cancelOrderCustomerGroups || '').split(',');
    return cancelOrderCustomerGroupsArray.indexOf(customerGroupId) > -1;
};

/** @namespace KemanaCancelOrders/Util/KemanaCancelOrders/Index/getCancelOrderSubmitData */
export const getCancelOrderSubmitData = (fields) => {
    const cancelOrderData = { items: [] };
    fields.forEach(({
        name, value, field
    }) => {
        if (name === CANCEL_ORDER_FIELDS.REASON || name === CANCEL_ORDER_FIELDS.COMMENT) {
            cancelOrderData[name] = value;
        } else {
            const index = Number(field.getAttribute('data-index'));
            if (!cancelOrderData.items[index]) {
                cancelOrderData.items[index] = { };
            }
            if (name === CANCEL_ORDER_FIELDS.ITEM_SKU) {
                cancelOrderData.items[index][name] = value;
            } else if (name === CANCEL_ORDER_FIELDS.ITEM_QTY) {
                cancelOrderData.items[index][name] = Number(value);
            }
        }
    });

    if (!cancelOrderData.items.length) {
        cancelOrderData.items = undefined;
    }

    return cancelOrderData;
};
