import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import InstallPrompt from 'Component/InstallPrompt';
import SourceRouterComponent, { withStoreRegex }
    from 'SourceComponent/Router/Router.component';

import {
    BEFORE_ITEMS_TYPE,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE
} from './Router.config';

export * from 'SourceComponent/Router/Router.component';

export const LazadaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "lazada" */ 'Route/LazadaPage'));
export const ShopeePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "shopee" */ 'Route/ShopeePage'));
export const WishlistShared = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'
));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
/** @namespace FullyBooked/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <InstallPrompt />,
            position: 1
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/lazadaauth/install/index/app/:appid?') } render={ (props) => <LazadaPage { ...props } /> } />,
            position: 180
        },
        {
            // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            component: <Route path={ withStoreRegex('/shopee/install/index') } render={ (props) => <ShopeePage { ...props } /> } />,
            position: 181
        },
        {
            component:
            <Route
              path={ withStoreRegex('/shared/wishlist/:code') }
              // eslint-disable-next-line react/jsx-no-bind
              render={ (props) => (
              <WishlistShared
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                { ...props }
              />
              ) }
            />,
            position: 81,
            name: SHARED_WISHLIST
        }
    ];
}

export default RouterComponent;
