import { FIELD_TYPE } from 'Component/Field/Field.config';

import KemanaDatePicker from '../component/KemanaDatePicker';

const renderDateInput = (setRef, attr, events, isDisabled) => (
    <KemanaDatePicker
      setRef={ setRef }
      attr={ attr }
      events={ events }
      isDisabled={ isDisabled }
    />
);

const renderMap = (originalMember, instance) => {
    const {
        setRef, attr, events, isDisabled
    } = instance.props;

    return {
        ...originalMember,
        [FIELD_TYPE.date]: () => renderDateInput(setRef, attr, events, isDisabled)
    };
};

export default {
    'Component/Field/Component': {
        'member-property': {
            renderMap
        }
    }
};
