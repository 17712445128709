import { lazy } from 'react';

import { MY_ACCOUNT_GIFT_CARD, MY_ACCOUNT_STORE_CREDIT } from '../../type/Account.type';

export const MyAccountGiftCard = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-gift-card" */
    '../../component/MyAccountGiftCard'
));
export const MyAccountStoreCredit = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-store-credit" */
    '../../component/MyAccountStoreCredit'
));

/** @namespace KemanaAccountStoreCredit/Route/MyAccount/Component/renderMap */
export const renderMap = (prevRenderMap) => ({
    ...prevRenderMap,
    [MY_ACCOUNT_STORE_CREDIT]: MyAccountStoreCredit,
    [MY_ACCOUNT_GIFT_CARD]: MyAccountGiftCard
});

export default { 'Route/MyAccount/Component': { 'member-property': { renderMap } } };
