import FIELD_TYPE from 'Component/Field/Field.config';
import CONFIG_FIELD_TYPE from 'Component/ProductCustomizableOption/ProductCustomizableOption.config';
/** @namespace KemanaGiftCardProductType/Util/GiftCard/getGiftCardFieldType */
export const getGiftCardFieldType = (type) => FIELD_TYPE[Object.keys(CONFIG_FIELD_TYPE)
    .find((key) => CONFIG_FIELD_TYPE[key] === type)] || FIELD_TYPE.text;

/** @namespace KemanaGiftCardProductType/Util/GiftCard/getGiftCardFieldTypeByTitle */
export const getGiftCardFieldTypeByTitle = (title) => {
    if (title === 'Sender Name' || title === 'Recipient Name') {
        return FIELD_TYPE.text;
    } if (title === 'Sender Email' || title === 'Recipient Email') {
        return FIELD_TYPE.email;
    } if (title === 'Message') {
        return FIELD_TYPE.textarea;
    }

    return FIELD_TYPE.text;
};
