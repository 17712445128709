import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';

import { EVENTS_PAGE_URL } from '../EventList/EventList.config';

// eslint-disable-next-line max-len
export const KemanaEvents = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kemanaEvents" */ '../../route/KemanaEvents'));

// eslint-disable-next-line max-len
export const KemanaEvent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "kemanaEvent" */ '../../route/KemanaEvent'));

/** @namespace KemanaEvents/Component/Router/Component/SWITCH_ITEMS_TYPE */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${EVENTS_PAGE_URL}/:eventId`) } render={ (props) => <KemanaEvent { ...props } /> } />,
        position: 101
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${EVENTS_PAGE_URL}`) } render={ (props) => <KemanaEvents { ...props } /> } />,
        position: 102
    }
];

export const kemanaEventsRouterComponentOverrides = {
    'member-property': {
        SWITCH_ITEMS_TYPE
    }
};
