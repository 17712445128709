/** @namespace KemanaAccountStoreCredit/Route/MyAccount/Container/tabMap */
import { THIRD_SECTION } from 'Type/Account.type';

import { MY_ACCOUNT_CASHBACK_ROUTE } from '../../component/MyAccountCashbackPoints/MyAccountCashbackPoints.config';
import { MY_ACCOUNT_CASHBACK } from '../../type/Account.type';

/** @namespace KemanaCashbackPoints/Route/MyAccount/Container/tabMap */
export const tabMap = (prevTabMap) => ({
    ...prevTabMap,
    [MY_ACCOUNT_CASHBACK]: {
        url: MY_ACCOUNT_CASHBACK_ROUTE,
        isFullUrl: true,
        tabName: __('My Cashback Points'),
        section: THIRD_SECTION
    }
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};
