/** @namespace KemanaStorePickup/Component/CheckoutShipping/Container/mapStateToProps */
import { PICK_UP_AT_STORE } from './CheckoutShipping.config';

/** @namespace KemanaStorePickupPlugin/Component/CheckoutShipping/Container/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        storeList: state.CheckoutReducer.storeList,
        selectedPickupStoreId: state.CheckoutReducer.selectedPickupStoreId
    };
};

/** @namespace KemanaStorePickupPlugin/Component/CheckoutShipping/Container/getShippingFields */
export const getShippingFields = (args, callback) => {
    const fieldData = callback(...args);
    const { data, data: { billing_address, shipping_address }, shipping_method_code } = fieldData;
    // eslint-disable-next-line no-unused-vars
    const { pickup_store, pickup_store_id, ...billingAddress } = billing_address;

    const {
        pickup_store: shippingPickupStoreName,
        pickup_store_id: shippingPickupStoreId,
        ...shippingAddress
    } = shipping_address;

    if (shipping_method_code === PICK_UP_AT_STORE) {
        shippingAddress.pickup_store_name = shippingPickupStoreName;
        shippingAddress.pickup_store_id = shippingPickupStoreId;
    }

    return {
        fieldData,
        data: { ...data, billing_address: billingAddress, shipping_address: shippingAddress }
    };
};

export default {
    'Component/CheckoutShipping/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            getShippingFields
        }
    }
};
