import { lazy } from 'react';

import { MY_ACCOUNT_CASHBACK } from '../../type/Account.type';

export const MyAccountCashbackPoints = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-cashback-points" */
    '../../component/MyAccountCashbackPoints'
));

/** @namespace KemanaCashbackPoints/Route/MyAccount/Component/renderMap */
export const renderMap = (prevRenderMap) => ({
    ...prevRenderMap,
    [MY_ACCOUNT_CASHBACK]: MyAccountCashbackPoints
});

export default { 'Route/MyAccount/Component': { 'member-property': { renderMap } } };
