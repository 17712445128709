import { Children } from 'react';

import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

/** @namespace FullyBooked/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    static defaultProps = {
        showArrows: true // Slider Arrows enabled by default
    };

    renderCrumbs() {
        const { children } = this.props;

        return (
          <div
            block="Slider"
            elem="Crumbs"
          >
              { Children.map(children, this.renderCrumb) }
          </div>
        );
    }

    renderArrows() {
        const { showArrows, activeImage, children } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                />
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                />
            </>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div
              block="Slider"
              mix={ mix }
              ref={ this.getSliderRef() }
            >
                    { this.renderSliderContent() }
                    <div
                      block="Slider"
                      elem="Arrows"
                    >
                      { this.renderArrows() }
                    </div>
                    { this.renderCrumbs() }
            </div>
        );
    }
}

export default SliderComponent;
