import PropTypes from 'prop-types';

export const giftCardAmountsType = PropTypes.arrayOf(
    PropTypes.shape({
        uid: PropTypes.string,
        website_id: PropTypes.string,
        value: PropTypes.string,
        attribute_id: PropTypes.string,
        website_value: PropTypes.string
    })
);

export const giftcardProductOptionsType = PropTypes.objectOf(
    PropTypes.shape({
        sender: PropTypes.string,
        sender_email: PropTypes.string,
        reciepient: PropTypes.string,
        reciepient_email: PropTypes.string
    })
);
