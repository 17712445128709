import { kemanEventsHeaderComponentOverrides } from '../component/Header/Header.component';
import { kemanaEventsRouterComponentOverrides } from '../component/Router/Router.component';
import { kemanaEventsStoreOverrides } from '../store';

export const config = {
    'Component/Router/Component': { ...kemanaEventsRouterComponentOverrides },
    'Component/Header/Component': { ...kemanEventsHeaderComponentOverrides },
    'Store/Index/getStaticReducers': { ...kemanaEventsStoreOverrides }
};

export default config;
