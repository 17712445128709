/** @namespace KemanaTrackShipping/Util/KemanaTrackShipping/Index/appendZero */
export const appendZero = (number) => (String(number).length === 1 ? `0${number}` : number);

/** @namespace KemanaTrackShipping/Util/KemanaTrackShipping/Index/getFormattedTrackingHistory */
export const getFormattedTrackingHistory = (histories) => histories
    .map(({ date_time, ...history }, index) => {
        const date = new Date(date_time);
        const dateString = `${appendZero(date.getMonth() + 1)}/${appendZero(date.getDate())}/${date.getFullYear()} `
        + `${appendZero(date.getHours())}:${appendZero(date.getMinutes())}`;

        return {
            ...history,
            key: index,
            date_time: dateString
        };
    });
