// import PropTypes from 'prop-types';

import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace FullyBooked/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...this.propTypes,
        subTitle: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        subTitle: __('(optional)')
    };

    /**
     * @extends to add subTitle
     */

    containerProps() {
        const { subTitle = '' } = this.props;
        return { ...super.containerProps(), subTitle };
    }
}

export default FieldContainer;
