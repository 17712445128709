import { lazy } from 'react';

import { _getOrderItemProductsFields, _getOrderItemTotalFields } from '../query/Order.query';
import { GiftWrapReducer } from '../store/GiftWrapper/GiftWrapper.reducer';

export const GiftWrapper = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-wrapper" */
    '../component/GiftWrapper'
));

export const WrapOrderSummary = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-wrapper" */
    '../component/WrapOrderSummary'
));

export const GiftWrapperPopup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-wrapper" */
    '../component/GiftWrapperPopup'
));

export const OrderPageGiftWrapper = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-wrapper" */
    '../component/OrderPageGiftWrapper'
));

export const GiftWrapLoader = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "gift-wrapper" */
    '../component/GiftWrapLoader'
));
const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    GiftWrapReducer
});

const renderGiftWrapper = (props) => {
    const { item: { item_id, qty, product: { salable_qty } } } = props;
    return (<GiftWrapper inStockItem={ salable_qty } cartItemPropsItemId={ item_id } cartItemPropsQty={ qty } />);
};
const renderGiftWrapPlugin = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        { renderGiftWrapper(instance.props) }
    </>
);

const renderGiftWrapperTotals = () => (
    <WrapOrderSummary />
);
const renderGiftWrapTotals = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        { renderGiftWrapperTotals() }
    </>
);

const renderGiftWrapperPopup = (props) => {
    const { totals: { id: cartId } } = props;

    if (cartId) {
        return (
            <>
                <GiftWrapperPopup cartId={ cartId } />
                <GiftWrapLoader />
            </>
        );
    }

    return null;
};
const renderCartItems = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        { renderGiftWrapperPopup(instance.props) }
    </>
);

const renderOrderPageGiftWrapperPopup = (props) => {
    const { product } = props;
    if (product) {
        return (<OrderPageGiftWrapper product={ product } />);
    }

    return null;
};
const renderWrapDetails = (args, callback, instance) => (
    <>
        { callback.apply(instance, args) }
        { renderOrderPageGiftWrapperPopup(instance.props) }
    </>
);

export const config = {
    'Component/CartItem/Component': {
        'member-function': {
            renderGiftWrapPlugin
        }
    },
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderGiftWrapTotals
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            renderCartItems

        }
    },
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    },
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemProductsFields,
            _getOrderItemTotalFields
        }
    },
    'Component/MyAccountOrderItemsTableRow/Component': {
        'member-function': {
            renderWrapDetails
        }
    }
};

export default config;
