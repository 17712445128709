import { UPDATE_LOGO } from './PaymentShippingLogo.action';

/** @namespace KemanaPaymentShippingLogo/Store/PaymentShipping/PaymentShippingLogo/Reducer/getInitialState */
export const getInitialState = () => ({
    logoData: {}
});

/** @namespace KemanaPaymentShippingLogo/Store/PaymentShipping/PaymentShippingLogo/Reducer/PaymentshippinglogoReducer */
export const PaymentshippinglogoReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        logoData
    } = action;

    switch (type) {
    case UPDATE_LOGO:
        return {
            ...state,
            ...logoData
        };

    default:
        return state;
    }
};

export default PaymentshippinglogoReducer;
