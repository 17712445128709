import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    STATUS_PASSWORD_UPDATED
} from 'Route/PasswordChangePage/PasswordChangePage.config';
import {
    MyAccountDispatcher as SourceMyAccountDispatcher
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerPasswordResetStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';
/**
 * @override to trigger UpdateOnlineCustomerDispatcher on singIn
 * @namespace FullyBooked/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    createAccount(options = {}, dispatch) {
        const { customerData: { email, password } } = options;
        const mutation = MyAccountQuery.getCreateAccountWithAddressMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace FullyBooked/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomerAndCustomerAddress: { customerInfo: { customer } } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace FullyBooked/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    /**
     *  @override to resetpassword with new resetpassword mutation
     */

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace FullyBooked/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch */
            ({ resetPassword }) => dispatch(
                updateCustomerPasswordResetStatus(resetPassword && STATUS_PASSWORD_UPDATED)
            ),
            /** @namespace FullyBooked/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch/catch */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }
}

export default new MyAccountDispatcher();
