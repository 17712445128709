/** @namespace FullyBooked/Util/InjectScript/hasScriptInjectedPreviosly */
export const hasScriptInjectedPreviosly = (url) => {
    const scriptInjected = window.injectedScripts?.includes(url);

    if (!scriptInjected) {
        if (!window.injectedScripts) {
            window.injectedScripts = [];
        }
        window.injectedScripts.push(url);

        return false;
    }

    return true;
};

/** @namespace FullyBooked/Util/InjectScript/injectScript */
export const injectScript = ({
    url, id, async = false, defer = false, onload
}) => {
    if (url && !hasScriptInjectedPreviosly(url)) {
        const script = document.createElement('script');
        script.src = url;
        if (id) {
            script.id = id;
        }
        if (async) {
            script.async = true;
        }

        if (defer) {
            script.defer = true;
        }

        if (onload) {
            script.onload = onload;
        }

        document.body.appendChild(script);
    }
};
