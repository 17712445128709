import CheckoutDeliveryOptionsComponentOverride
    from '../component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component';
import CheckoutDeliveryOptionsContainerOverride
    from '../component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container';
import CheckoutShippingContainerOverride
    from '../component/CheckoutShipping/CheckoutShipping.container';
import CheckoutReducerOverride from '../store/Checkout/Checkout.reducer';

export const config = {
    ...CheckoutDeliveryOptionsComponentOverride,
    ...CheckoutDeliveryOptionsContainerOverride,
    ...CheckoutShippingContainerOverride,
    ...CheckoutReducerOverride
};

export default config;
