import { ORDER_REFUNDS } from 'Component/MyAccountOrder/MyAccountOrder.config';

import { ORDER_RETURNS } from '../MyAcccountOrder/MyAccountOrder.config';

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Component/renderItemsHeading */
export const renderItemsHeading = (args, callback, context) => {
    const { activeTab, isMobile } = context.props;

    if (!isMobile && activeTab === ORDER_RETURNS) {
        return (
            <div
              block="MyAccountOrderReturnTab"
              elem="TableHeading"
              mix={ { block: 'KeyValueTable', elem: 'Row' } }
            >
                <div block="KeyValueTable" elem="Heading">{ __('Return #') }</div>
                <div block="KeyValueTable" elem="Heading">{ __('Date') }</div>
                <div block="KeyValueTable" elem="Heading">{ __('Ship From') }</div>
                <div block="KeyValueTable" elem="Heading">{ __('Return Status') }</div>
                <div block="KeyValueTable" elem="Heading">{ ' ' }</div>
            </div>
        );
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Component/renderRefundsTableHeading */
export const renderRefundsTableHeading = (args, callback, context) => {
    const { activeTab } = context.props;

    if (activeTab === ORDER_REFUNDS) {
        return null;
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Component/renderOrderTitleAndActions */
export const renderOrderTitleAndActions = (args, callback, context) => {
    const { activeTab, items: { orderNumber } } = context.props;

    if (activeTab === ORDER_RETURNS) {
        return (
            <div
              block="MyAccountOrderItemsTable"
              elem="OrderTitle"
              mix={ { block: 'MyAccountOrderItemsTable', elem: 'ReturnTitle' } }
            >
                    { __('My Return') }
            </div>
        );
    }

    if (activeTab === ORDER_REFUNDS) {
        return (
            <div block="MyAccountOrderItemsTable" elem="OrderTitle">
                { __('Refund Items for Order #%s', orderNumber) }
            </div>
        );
    }

    return callback.apply(context, args);
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Component/renderTotals */
export const renderTotals = (args, callback, context) => {
    const { activeTab } = context.props;

    if (activeTab === ORDER_RETURNS) {
        return null;
    }

    return callback.apply(context, args);
};

export const myAccountOrderItemsTableComponentOverrides = {
    'member-function': {
        renderItemsHeading,
        renderOrderTitleAndActions,
        renderTotals,
        renderRefundsTableHeading
    }
};

export default myAccountOrderItemsTableComponentOverrides;
