(() => {
    /**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
    if (!Object.fromEntries) {
        Object.fromEntries = function (entries) {
            if (!entries || !entries[Symbol.iterator]) {
                throw new Error('Object.fromEntries() requires a single iterable argument');
            }
            const obj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of entries) {
                obj[key] = value;
            }

            return obj;
        };
    }

    // Object.entriesFrom() polyfill

    (function () {
        if (typeof globalThis === 'object') {
            return;
        }
        // eslint-disable-next-line no-extend-native
        Object.defineProperty(Object.prototype, '__magic__', {
            get() {
                return this;
            },
            configurable: true
        });
        // eslint-disable-next-line no-undef
        __magic__.globalThis = __magic__;
        // eslint-disable-next-line fp/no-delete
        delete Object.prototype.__magic__;
    }());
})();
