export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
/** @namespace FullyBooked/Store/CurrentStep/Action/action */

/**
 * PLEASE NOTE THAT THIS REDUCER CAN BE ONLY USED IN CHECKOUT
 * @param {String} step
 */

export const action = (step) => ({
    type: SET_CURRENT_STEP,
    step
});
