export const MY_ACCOUNT_CASHBACK_ROUTE = '/customer/cashback-points';
export const CASH_BACK_POINTS_ACTION_TITLE = __('Amount');
export const CASH_BACK_POINTS_TRANSACTION_TITLE = __('Transaction Details');
export const CASH_BACK_POINTS_BALANCE_TITLE = __('Balance');
export const CASH_BACK_POINTS_DATE_TITLE = __('Date');
export const CASH_BACK_POINTS_TITLE = __('Points');
export const CASH_BACK_POINTS_REDEEM_ROUTE = '/faqs';
export const CASH_BACK_SUBCRIPTION_STATUS = {
    SUBSCRIBED: 'SUBSCRIBED',
    NOT_SUBSCRIBED: 'NOT_SUBSCRIBED'
};
