export const COORDINATE_TYPES = {
    NEARBY: 'NEARBY',
    SELECTED_PLACE: 'SELECTED_PLACE',
    NONE: 'NONE'
};

export const STORE_STATUSES = {
    OPEN: 'Open',
    CLOSED: 'Closed'
};

export const STORE_PAGE_SIZE = 200;
export const MINUTE_FRACTION = 0.01;
export const STORE_LOCATOR = 'store_loctor';
