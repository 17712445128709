import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Type/NotificationList.type';
import history from 'Util/History';
import { appendWithStoreCode, replace } from 'Util/Url';

import NotificationComponent from './Notification.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
/** @namespace FullyBooked/Component/Notification/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    baseLinkUrl: state.ConfigReducer.base_link_url
});
/** @namespace FullyBooked/Component/Notification/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, false, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace FullyBooked/Component/Notification/Container */
export class NotificationContainer extends PureComponent {
    static propTypes = {
        notificationId: PropTypes.string.isRequired,
        notification: NotificationType.isRequired,
        onHideNotification: PropTypes.func.isRequired,
        lifeTime: PropTypes.number,
        id: PropTypes.string,
        logout: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        lifeTime: 0,
        id: ''
    };

    containerFunctions = {
        handleLogout: this.handleLogout.bind(this)
    };

    handleLogout() {
        const { baseLinkUrl, logout, showNotification } = this.props;

        const path = baseLinkUrl
            ? appendWithStoreCode(ACCOUNT_LOGIN_URL)
            : replace(/\/customer\/account\/.*/, ACCOUNT_LOGIN_URL);

        history.push({
            pathname: path,
            state: { isFromEmailChange: true }
        });
        logout();
        showNotification('error', __('Your session is over, you are logged out!'));
    }

    containerProps() {
        const {
            notificationId, notification, onHideNotification, lifeTime, id
        } = this.props;

        return {
            notificationId, notification, onHideNotification, lifeTime, id
        };
    }

    render() {
        return (
             <NotificationComponent
               { ...this.containerProps() }
               { ...this.containerFunctions }
             />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
