import CloseIcon from 'Component/CloseIcon';
import { InstallPromptIOS as SourceInstallPromptIOS }
    from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';

/** @namespace FullyBooked/Component/InstallPromptIOS/Component */
export class InstallPromptIOSComponent extends SourceInstallPromptIOS {
    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button
              block="InstallPromptIOS"
              elem="Close"
              onClick={ handleBannerClose }
              aria-label={ __('Close') }
            >
                <CloseIcon />
            </button>
        );
    }

    renderHeading() {
        return (
            <h6 block="InstallPromptIOS" elem="Heading">
                { __('Browse website in full-screen:') }
            </h6>
        );
    }

    render() {
        return (
            <div block="InstallPromptIOS">
                { this.renderCloseButton() }
                <div block="InstallPromptIOS" elem="Left">
                    { this.renderHeading() }
                    { this.renderContent() }
                </div>
            </div>
        );
    }
}

export default InstallPromptIOSComponent;
