import {
    kemanaCancelOrderMyAccountOrdersComponentOverrides
} from '../component/MyAccountMyOrders/MyAccountMyOrders.component';
import {
    kemanaCancelOrderMyAccountOrdersContainerMapStateOverrides,
    kemanaCancelOrderMyAccountOrdersContainerOverrides
} from '../component/MyAccountMyOrders/MyAccountMyOrders.container';
import { kemanaCancelOrderMyAccountOrderTableRowComponentOverrides }
    from '../component/MyAccountOrderTableRow/MyAccountOrderTableRow.component';
import {
    kemanaCancelOrderMyAccountOrderTableRowContainerDispatchOverrides,
    kemanaCancelOrderMyAccountOrderTableRowContainerMapStateOverrides,
    kemanaCancelOrderMyAccountOrderTableRowContainerOverrides
}
    from '../component/MyAccountOrderTableRow/MyAccountOrderTableRow.container';
import kemanaCancelOrderConfigOverrides from '../query/Config.query';
import { kamanaCancelOrdersOrderQueryOverrides } from '../query/Order.query';

export const config = {
    'Component/MyAccountOrderTableRow/Component': {
        ...kemanaCancelOrderMyAccountOrderTableRowComponentOverrides
    },
    'Component/MyAccountOrderTableRow/Container': {
        ...kemanaCancelOrderMyAccountOrderTableRowContainerOverrides
    },
    'Component/MyAccountOrderTableRow/Container/mapDispatchToProps': {
        ...kemanaCancelOrderMyAccountOrderTableRowContainerDispatchOverrides
    },
    'Component/MyAccountOrderTableRow/Container/mapStateToProps': {
        ...kemanaCancelOrderMyAccountOrderTableRowContainerMapStateOverrides
    },
    'Component/MyAccountMyOrders/Component': {
        ...kemanaCancelOrderMyAccountOrdersComponentOverrides
    },
    'Component/MyAccountMyOrders/Container': {
        ...kemanaCancelOrderMyAccountOrdersContainerOverrides
    },
    'Component/MyAccountMyOrders/Container/mapStateToProps': {
        ...kemanaCancelOrderMyAccountOrdersContainerMapStateOverrides
    },
    'Query/Config/Query': {
        ...kemanaCancelOrderConfigOverrides
    },
    'Query/Order/Query': {
        ...kamanaCancelOrdersOrderQueryOverrides
    }
};

export default config;
