import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { MY_ACCOUNT_GIFT_CARD, MY_ACCOUNT_STORE_CREDIT } from '../../type/Account.type';
import { MY_ACCOUNT_GIFT_CARD_ROUTE } from '../MyAccountGiftCard/MyAccountGiftCard.config';
import { MY_ACCOUNT_STORE_CREDIT_ROUTE } from '../MyAccountStoreCredit/MyAccountStoreCredit.config';

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

/** @namespace KemanaAccountStoreCredit/Component/Router/Component/SWITCH_ITEMS_TYPE */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const SWITCH_ITEMS_TYPE = (originalMembers) => [
    ...originalMembers,
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${MY_ACCOUNT_STORE_CREDIT_ROUTE}`) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ACCOUNT_STORE_CREDIT } /> } />,
        position: 93,
        name: MY_ACCOUNT_STORE_CREDIT
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${MY_ACCOUNT_GIFT_CARD_ROUTE}`) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ACCOUNT_GIFT_CARD } /> } />,
        position: 94,
        name: MY_ACCOUNT_GIFT_CARD
    }
];

export default {
    'Component/Router/Component': {
        'member-property': { SWITCH_ITEMS_TYPE }
    }
};
