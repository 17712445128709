import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace FullyBooked/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getCreateAccountWithAddressMutation(input) {
        return new Field('createCustomerAndCustomerAddress')
            .addArgument('input', 'CustomerAndCustomerAddressInput!', input)
            .addField(this._getCustomerRegField())
            .addField(this._getAddressesRegField());
    }

    _getCustomerRegField() {
        return new Field('customerInfo')
            .addField(this._getCustomerDataField());
    }

    _getCustomerDataField() {
        return new Field('customer')
            .addFieldList(this._getCustomerRegFields());
    }

    _getCustomerRegFields() {
        return [
            'prefix',
            'firstname',
            'lastname',
            'email',
            'date_of_birth',
            'taxvat',
            'phone_number',
            'confirmation_required',
            'is_subscribed'
        ];
    }

    _getAddressesRegField() {
        return new Field('customerAddressData')
            .addFieldList(this._getAddressFieldsRegList());
    }

    _getAddressFields() {
        return [
            'unit_number',
            'district_id',
            'city_id',
            'district',
            ...super._getAddressFields()
        ];
    }

    _getAddressFieldsRegList() {
        return [
            'firstname',
            'lastname',
            'telephone',
            'street',
            'city',
            'postcode',
            'district_id',
            'unit_number',
            'street',
            'country_code'
        ];
    }

    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            ...this._getCustomerCustomFields(),
            this._getStoreCreditField(),
            this._getRewardsPointField()
        ];
    }

    _getCustomerCustomFields() {
        return ['gender', 'phone_number'];
    }

    _getStoreCreditField() {
        return new Field('store_credit')
            .addField(this._getStoreCreditCurrentBalanceField());
    }

    _getRewardsPointField() {
        return new Field('reward_points')
            .addField(this._getRewardsPointBalanceField());
    }

    _getStoreCreditCurrentBalanceField() {
        return new Field('current_balance')
            .addFieldList(this._getStoreCreditCurrentBlanceFieldList());
    }

    _getRewardsPointBalanceField() {
        return new Field('balance')
            .addFieldList(this._getBalanceFieldList());
    }

    _getStoreCreditCurrentBlanceFieldList() {
        return [
            'value',
            'currency'
        ];
    }

    _getBalanceFieldList() {
        return [
            'points',
            this._getMoneyFiled()
        ];
    }

    _getMoneyFiled() {
        return new Field('money')
            .addFieldList(this._getMoneyFieldList());
    }

    _getMoneyFieldList() {
        return [
            'currency',
            'value'
        ];
    }
    /**
     *  @override to resetpassword with new resetpassword mutation
     */

    getResetPasswordMutation(options) {
        const { token, email, newPassword } = options;

        return new Field('resetPassword')
            .addArgument('resetPasswordToken', 'String!', token)
            .addArgument('email', 'String!', email)
            .addArgument('newPassword', 'String!', newPassword);
    }
}

export default new MyAccountQuery();
