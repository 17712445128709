/** @namespace KemanaCancelOrders/Component/MyAccountMyOrders/Component/renderOrderHeadingRow */
import MyAccountCancelOrderPopup from '../MyAccountCancelOrderPopup';

/** @namespace KemanaCancelOrders/Component/MyAccountMyOrders/Component/renderCancelOrderRow */

/**
 * Function must be created in Component/MyAccountMyOrders/Component
 */

export const renderCancelOrderRow = () => <div block="KeyValueTable" elem="Heading"> </div>;

/** @namespace KemanaCancelOrders/Component/MyAccountMyOrders/Component/render */
export const render = (args, callback) => (
    <>
        <MyAccountCancelOrderPopup />
        { callback(args) }
    </>
);

export const kemanaCancelOrderMyAccountOrdersComponentOverrides = {
    'member-function': {
        render,
        renderCancelOrderRow
    }
};
