import kemanaTrackShippingMyAccountOrderItemsTableOverrides
    from '../component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable.component';
import kemanaTrackShippingMyAccountOrderItemsTableContainerOverrides
, {
    kemanaTrackShippingMyAccountOrderItemsTableContainerDispatchOverrides
} from '../component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable.container';

export const config = {
    'Component/MyAccountOrderItemsTable/Component': {
        ...kemanaTrackShippingMyAccountOrderItemsTableOverrides
    },
    'Component/MyAccountOrderItemsTable/Container': {
        ...kemanaTrackShippingMyAccountOrderItemsTableContainerOverrides
    },
    'Component/MyAccountOrderItemsTable/Container/mapDispatchToProps': {
        ...kemanaTrackShippingMyAccountOrderItemsTableContainerDispatchOverrides
    }
};

export default config;
