import routerComponent from '../component/Router/Router.component';
import myAccountRouteComponent from '../route/MyAccount/MyAccount.component';
import myAccountRouteContainer from '../route/MyAccount/MyAccount.container';

const config = {
    ...routerComponent,
    ...myAccountRouteContainer,
    ...myAccountRouteComponent
};

export default config;
