import { formatPrice } from 'Util/Price';
import { Field, Fragment } from 'Util/Query';

import GiftCardProductCartItemOptions from '../component/GiftCardProductCartItemOptions';
import GiftCardProductOptions from
    '../component/GiftCardProductOptions';
import { PRODUCT_TYPE } from '../component/GiftCardProductOptions/GiftCardProductOptions.config';
// Extending ProductList, Cart Queries and addding giftCard Product Data

const _getGiftcardAmountFields = () => ['uid', 'website_id', 'value', 'attribute_id', 'website_value'];

const _getGiftcardAmounts = () => new Field('giftcard_amounts')
    .addFieldList(_getGiftcardAmountFields());

const _getGiftCardOptionFields = () => ['uid', 'title', 'required', 'type'];

const _getGiftCardOptions = () => new Field('gift_card_options')
    .addFieldList(_getGiftCardOptionFields());

const _getGiftCardFields = () => [
    'allow_message',
    'message_max_length',
    'allow_open_amount',
    'open_amount_min',
    'open_amount_max',
    'is_returnable',
    'is_redeemable',
    'giftcard_type'];

const _getGiftCardProductFragment = () => new Fragment('GiftCardProduct')
    .addFieldList(_getGiftCardFields())
    .addField(_getGiftCardOptions())
    .addField(_getGiftcardAmounts());

const _getProductInterfaceFields = (args, callback, instance) => {
    const [isVariant] = args;

    const fields = callback.apply(instance, args);

    if (!isVariant) {
        fields.push(
            _getGiftCardProductFragment()
        );
    }

    return fields;
};

const _getGiftCardProductOptionsFields = () => new Field('giftcard_product_options')
    .addFieldList([
        'sender',
        'sender_email',
        'reciepient',
        'reciepient_email'
    ]);

const _getCartItemFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);
    return [...fields, _getGiftCardProductOptionsFields()];
};

// Rendering Price for Gift Card Product type

const getGiftCartProductPrice = ({
    allow_open_amount,
    giftcard_amounts = [],
    open_amount_max,
    open_amount_min,
    currency
}) => {
    if (allow_open_amount && open_amount_min === open_amount_max) {
        return formatPrice(open_amount_min, currency);
    } if (allow_open_amount && open_amount_min !== open_amount_max) {
        return (
            <span block="ProductPrice" elem="PriceLabel">
                { __('From ') }
                { formatPrice(open_amount_min, currency) }
            </span>
        );
    }

    const arrayOfGiftCardAmounts = giftcard_amounts.map(({ value }) => value);
    if (arrayOfGiftCardAmounts.length) {
        return formatPrice(Math.min(...arrayOfGiftCardAmounts), currency);
    }

    return false;
};

const giftCardrenderPrice = (instance) => {
    const { getActiveProduct, productPrice } = instance.props;
    const {
        allow_open_amount, giftcard_amounts, open_amount_max, open_amount_min,
        price_range: {
            minimum_price: {
                regular_price: {
                    currency = false
                } = {}
            } = {}
        } = {}
    } = getActiveProduct();

    if (!productPrice && !currency) {
        return null;
    }

    const options = {
        allow_open_amount, giftcard_amounts, open_amount_max, open_amount_min, currency
    };

    const price = getGiftCartProductPrice(options);

    if (!price) {
        return false;
    }

    return (
        <span block="ProductPrice" elem="Price">
            <span block="ProductPrice" elem="PriceValue">
                { price }
            </span>
        </span>
    );
};

const renderPrice = (args, callback, instance) => {
    const { getActiveProduct } = instance.props;
    const { type_id } = getActiveProduct?.();

    if (type_id === PRODUCT_TYPE.giftcard) {
        return giftCardrenderPrice(instance);
    }

    return callback.apply(instance, args);
};

// Pluging Product , Cart Query and overriding renderPrice function for giftcard type products

const renderGiftCartOptions = (args, callback, instance) => (
    <GiftCardProductOptions
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
      { ...instance.props }

    />
);

const renderGiftCardProductCartItemOptions = (args, callback, instance) => (
    <GiftCardProductCartItemOptions
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
      { ...instance.props }
      renderProductOptions={ instance.renderProductOptions }
      instance={ instance }

    />
);

export default {

    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields
        }
    },
    'Query/Cart/Query': {
        'member-function': {
            _getCartItemFields
        }
    },
    'Component/Product/Component': {
        'member-function': {
            renderPrice
        }
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderGiftCartOptions
        }
    },
    'Component/CartItem/Component': {
        'member-function': {
            renderGiftCardProductCartItemOptions
        }
    }
};
