/** @namespace KemanaEvents/Component/Header/Component/stateMap */
import { KEMANA_EVENT, KEMANA_EVENTS } from './Header.config';

/** @namespace KemanaEvents/Component/Header/Component/stateMap */
export const stateMap = (originalMember) => ({
    [KEMANA_EVENTS]: {
        title: true,
        search: false,
        back: true
    },
    [KEMANA_EVENT]: {
        title: true,
        search: false,
        back: true
    },
    ...originalMember
});

export const kemanEventsHeaderComponentOverrides = {
    'member-property': { stateMap }
};
