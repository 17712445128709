import { UPDATE_FREE_GIFT_LOADER, UPDATE_FREE_GIFTS } from './FreeGifts.action';

/** @namespace KemanaFreeGifts/Store/FreeGifts/Reducer/getInitialState */
export const getInitialState = () => ({
    FreeGifts: []
});

/** @namespace KemanaFreeGifts/Store/FreeGifts/Reducer/FreeGiftsReducer */
export const FreeGiftsReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        FreeGifts,
        FreeGiftLoader = false
    } = action;

    switch (type) {
    case UPDATE_FREE_GIFTS:
        return {
            ...state,
            FreeGifts
        };

    case UPDATE_FREE_GIFT_LOADER:
        return {
            ...state,
            FreeGiftLoader
        };

    default:
        return state;
    }
};

export default FreeGiftsReducer;
