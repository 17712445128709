import { KEMANA_EVENTS_SET_EVENT_DETAILS } from './KemanaEvents.action';

/** @namespace KemanaEvents/Store/KemanaEvents/Reducer/getInitialState */
export const getInitialState = () => ({
    currentEvent: {}
});

/** @namespace KemanaEvents/Store/KemanaEvents/Reducer/KemanaEventsReducer */
export const KemanaEventsReducer = (
    state = getInitialState(),
    action
) => {
    const { type, currentEvent } = action;

    switch (type) {
    case KEMANA_EVENTS_SET_EVENT_DETAILS:
        return { ...state, currentEvent };
    default:
        return state;
    }
};

export default KemanaEventsReducer;
