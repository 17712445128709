/** @namespace KemanaCancelOrders/Query/Config/Query/_getCancelOrderFields */
export const _getCancelOrderFields = () => [
    'cancel_order_enable',
    'cancel_order_customer_groups',
    'cancel_order_order_status',
    'cancel_order_order_button_label',
    'cancel_order_order_enable_popup',
    'cancel_order_order_popup_notice',
    'cancel_order_order_cancellation_reason',
    'cancel_order_order_required_field'
];

/** @namespace KemanaCancelOrders/Query/Config/Query/_getStoreConfigFields */
export const _getStoreConfigFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        ..._getCancelOrderFields()
    ];
};

export const kemanaCancelOrderConfigOverrides = {
    'member-function': {
        _getStoreConfigFields
    }
};

export default kemanaCancelOrderConfigOverrides;
