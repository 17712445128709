import { InstallPromptAndroid as SourceInstallPromptAndroid }
    from 'SourceComponent/InstallPromptAndroid/InstallPromptAndroid.component';

/** @namespace FullyBooked/Component/InstallPromptAndroid/Component */
export class InstallPromptAndroidComponent extends SourceInstallPromptAndroid {
    renderContent() {
        return (
            <div block="InstallPromptAndroid" elem="Left">
                <h6 block="InstallPromptAndroid" elem="Heading">
                    { __('Add to your home screen') }
                </h6>
                <p block="InstallPromptAndroid" elem="Content">
                    { __('for the full browsing experience') }
                </p>
            </div>
        );
    }

    renderInstallButton() {
        const { handleAppInstall } = this.props;

        return (
            <button
              block="InstallPromptAndroid"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ handleAppInstall }
            >
                { __('Add Now') }
            </button>
        );
    }
}

export default InstallPromptAndroidComponent;
