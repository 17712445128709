import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

import {
    IMAGE_LOADING
} from './Image.config';
/** @namespace FullyBooked/Component/Image/Component */
export class ImageComponent extends SourceImage {
    /**
     * @extends to add Lazy Loading based on LazyLoad Prop
     */

    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
            lazyLoad
        } = this.props;
        const { imageStatus } = this.state;

        return (
        <img
          block="Image"
          elem="Image"
          src={ src || '' }
          alt={ alt }
          mods={ { isLoading: imageStatus === IMAGE_LOADING } }
          style={ style }
          title={ title }
          onLoad={ this.onLoad }
          onError={ this.onError }
          loading={ lazyLoad ? 'lazy' : false }
        />
        );
    }

    /**
     * @extends to add Lazy Loading based on LazyLoad Prop
     */

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            lazyLoad
        } = this.props;

        return (
        <img
          block={ className }
          src={ src || '' }
          alt={ alt }
          style={ style }
          title={ title }
          onLoad={ this.onLoad }
          onError={ this.onError }
          loading={ lazyLoad ? 'lazy' : false }
        />
        );
    }
}

export default ImageComponent;
