import { Field } from 'Util/Query';
/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemTotalAmtFields */
export const _getOrderItemTotalAmtFields = () => [
    'value',
    'currency'
];
/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemWrapImagesFields */
export const _getOrderItemWrapImagesFields = () => [
    'url',
    'name'
];
/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemCardFields */
export const _getOrderItemCardFields = () => new Field('postcard_image')
    .addFieldList(_getOrderItemWrapImagesFields());

/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemWrapFields */
export const _getOrderItemWrapFields = () => new Field('wrapper_image')
    .addFieldList(_getOrderItemWrapImagesFields());

/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemOtherWrappersFields */
export const _getOrderItemOtherWrappersFields = () => [
    'order_item_id',
    'wrapper_name',
    'wrapper_id',
    'postcard_name',
    'postcard_id'
];
/** @namespace GiftWrapper/Query/Order/Query/_getOrderWrappingField */
export const _getOrderWrappingField = () => new Field('wrappers')
    .addFieldList(_getOrderItemOtherWrappersFields())
    .addField(_getOrderItemWrapFields())
    .addField(_getOrderItemCardFields());

/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemProductsFields */
export const _getOrderItemProductsFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        _getOrderWrappingField()
    ];
};
/** @namespace GiftWrapper/Query/Order/Query/_getOrderWrapTotalFields */
export const _getOrderWrapTotalFields = () => new Field('gift_wrapper_amount')
    .addFieldList(_getOrderItemTotalAmtFields());

/** @namespace GiftWrapper/Query/Order/Query/_getOrderItemTotalFields */
export const _getOrderItemTotalFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        _getOrderWrapTotalFields()
    ];
};

export const kamanaGiftWrapOverrides = {
    'member-function': {
        _getOrderItemProductsFields
    }
};
