import PropTypes from 'prop-types';

import {
    ImageContainer as SourceImageContainer
} from 'SourceComponent/Image/Image.container';

/** @namespace FullyBooked/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static propTypes = {
        ...this.propTypes,
        lazyLoad: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        lazyLoad: true
    };

    /**
     * @override to add lazyLoad prop
     */

    containerProps() {
        const { lazyLoad } = this.props;
        return {
            ...super.containerProps(),
            lazyLoad
        };
    }
}

export default ImageContainer;
