export const DATE_PICKER_LIST_OF_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const DATE_PICKER_FORMAT = 'MM/dd/yyyy';
export const DATE_PICKER_RANGE_START_YEAR = 1920;
export const DATE_PICKER_MIN_ACCEPTED_YEAR = 0;
