import getStore from 'Util/Store';

import googleTagManager from '../util/Google/googleTagManager';

const _getStoreConfigFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'gtm_default_enable',
    'gtm_default_container_id'
];

const HeaderComponentDidMount = async (args, callback, instance) => {
    callback.apply(instance, args);

    const state = getStore().getState();
    const { gtm_default_enable, gtm_default_container_id } = state.ConfigReducer;
    if (gtm_default_enable && gtm_default_container_id) {
        googleTagManager.init(gtm_default_container_id);
    }
};

const InstallPromptCounter = async (args, callback, instance) => {
    callback.apply(instance, args);
    googleTagManager.installPromptCounter();
};

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount: HeaderComponentDidMount
        }
    },
    'Component/InstallPrompt/Container': {
        'member-function': {
            handleAppInstall: InstallPromptCounter
        }
    }
};
