export const UPDATE_PICKUP_STORE_LIST = 'UPDATE_PICKUP_STORE_LIST';
export const UPDATE_SELECTED_PICKUP_STORE_ID = 'UPDATE_SELECTED_PICKUP_STORE_ID';

/** @namespace KemanaStorePickupPlugin/Store/Checkout/Action/updatePickupStoreList */
export const updatePickupStoreList = (storeList) => ({
    type: UPDATE_PICKUP_STORE_LIST,
    storeList
});

/** @namespace KemanaStorePickupPlugin/Store/Checkout/Action/updateSelectedPickupStoreId */
export const updateSelectedPickupStoreId = (selectedPickupStoreId) => ({
    type: UPDATE_SELECTED_PICKUP_STORE_ID,
    selectedPickupStoreId
});
