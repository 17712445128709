import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';
import { THIRD_SECTION } from 'Type/Account.type';

import { CUSTOMER_RETURN_ORDER } from '../component/Header/Header.config';
import myAccountOrderComponentOverrides from '../component/MyAcccountOrder/MyAccountOrder.component';
import myAccountOrderItemsTableComponentOverrides
    from '../component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable.component';
import myAccountOrderItemsTableRowComponentOverrides
    from '../component/MyAcccountOrderItemsTableRow/MyAccountOrderItemsTableRow.component';
import { CREATE_RETURN_URL, MY_RETURN_URL } from '../component/MyAccount/MyAccount.config';
import myAccountOrderTotalsContainerOverrides from '../component/MyAccountOrderTotals/MyAccountOrderTotals.container';
import orderQueryOverrides from '../query/Order.query';
import ReturnOrderReducer from '../store/ReturnOrder/ReturnOrder.reducer';
import { CREATE_RETURN, MY_RETURN, MY_RETURNS } from '../type/Account.type';

// eslint-disable-next-line max-len
export const MyAccountMyReturns = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "returns" */ '../component/MyAccountMyReturns'));
// eslint-disable-next-line max-len
export const MyAccountMyReturn = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "return" */ '../component/MyAccountMyReturn'));
// eslint-disable-next-line max-len
export const MyAccountCreateReturn = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "createReturn" */ '../component/MyAccountCreateReturn'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${MY_RETURN_URL}/:orderId`) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> } />,
        position: 89,
        name: MY_RETURN
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${CREATE_RETURN_URL}/:orderId`) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> } />,
        position: 90,
        name: CREATE_RETURN
    },
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex('/customer/my-returns') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_RETURNS } /> } />,
        position: 91,
        name: MY_RETURNS
    }
];

const tabMap = (originalMember) => ({
    ...originalMember,
    [MY_RETURNS]: {
        url: '/customer/my-returns',
        tabName: __('My Returns'),
        isFullUrl: true,
        section: THIRD_SECTION
    }
});

const renderMap = (originalMember) => ({
    ...originalMember,
    [MY_RETURN]: MyAccountMyReturn,
    [MY_RETURNS]: MyAccountMyReturns,
    [CREATE_RETURN]: MyAccountCreateReturn
});

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    ReturnOrderReducer
});

const getTabContent = (args, callback, instance) => {
    const { activeTab, location: { pathname } } = instance.props;

    if (activeTab === MY_RETURNS && pathname.includes(CREATE_RETURN_URL)) {
        return instance.renderMap[CREATE_RETURN];
    }

    if (activeTab === MY_RETURNS && pathname.includes(MY_RETURN_URL)) {
        return instance.renderMap[MY_RETURN];
    }

    return callback.apply(instance, args);
};

const headerStateMap = (originalMember) => ({
    ...originalMember,
    [CUSTOMER_RETURN_ORDER]: {
        title: true,
        search: false,
        back: true
    }
});

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Component/MyAccountOrder/Component': {
        ...myAccountOrderComponentOverrides
    },
    'Component/MyAccountOrderItemsTable/Component': {
        ...myAccountOrderItemsTableComponentOverrides
    },
    'Component/MyAccountOrderItemsTableRow/Component': {
        ...myAccountOrderItemsTableRowComponentOverrides
    },
    'Component/MyAccountOrderTotals/Container': {
        ...myAccountOrderTotalsContainerOverrides
    },
    'Query/Order/Query': { ...orderQueryOverrides },
    'Component/Header/Component': {
        'member-property': {
            stateMap: headerStateMap
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            getTabContent
        }
    },
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
