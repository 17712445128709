import { Field } from 'Util/Query';
/** @namespace KemanaReturnOrders/Query/Order/Query/_getReturnOrderFields */
export const _getReturnOrderFields = () => new Field('returns')
    .addField(new Field('items')
        .addFieldList([
            'uid', 'status', 'fe_status', 'number', 'created_at', 'status',
            new Field('customer')
                .addFieldList(['firstname', 'lastname'])
        ]));

/** @namespace KemanaReturnOrders/Query/Order/Query/_getSingleOrderFields */
export const _getSingleOrderFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        _getReturnOrderFields()
    ];
};

/** @namespace KemanaReturnOrders/Query/Order/Query/_getOrderItemProductsFields */
export const _getOrderItemProductsFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        new Field('product_thumbnail')
            .addFieldList(['url', 'label'])
    ];
};

export const orderQueryOverrides = {
    'member-function': {
        _getSingleOrderFields,
        _getOrderItemProductsFields
    }
};

export default orderQueryOverrides;
