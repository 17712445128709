export * from 'SourceUtil/Common';

/** @namespace FullyBooked/Util/Common/Index/decodeString */
export const decodeString = (string) => {
    try {
        return decodeURIComponent(string);
    } catch (e) {
        return string;
    }
};
