import PropTypes from 'prop-types';

import {
    SEARCH
} from 'Component/Header/Header.config';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';
import { NavigationStateType } from 'Type/Router.type';

import './Overlay.style';
/** @namespace FullyBooked/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    static propTypes = {
        ...this.propTypes,
        navigationStateHistory: PropTypes.arrayOf(NavigationStateType)
    };

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            navigationStateHistory,
            isStatic
        } = this.props;
        const mainPage = navigationStateHistory[navigationStateHistory.length - 2 ] || null;
        const clickedSearch = navigationStateHistory[navigationStateHistory.length - 1 ] || null;
        // eslint-disable-next-line max-len
        const isHome = !!(mainPage?.name === DEFAULT_STATE_NAME && clickedSearch?.name === SEARCH) || clickedSearch?.name === DEFAULT_STATE_NAME;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ {
                  isVisible, isStatic, isInstant: areOtherOverlaysOpen, isHome
              } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
