import { ORDER_SHIPMENTS } from 'Component/MyAccountOrder/MyAccountOrder.config';

import MyAccountTrackShipping from '../MyAccountTrackShipping';

/** @namespace KemanaTrackShipping/Component/MyAcccountOrderItemsTable/MyAccountOrderItemsTable/Component/renderOrderTitleAndActions */
export const renderOrderTitleAndActions = (args, callback, context) => {
    const { activeTab, items: { number }, onTrackShipmentClick } = context.props;

    if (activeTab === ORDER_SHIPMENTS) {
        return (
            <div block="MyAccountOrderItemsTable" elem="OrderTitle">
                { `${activeTab} # ${number}` }
                <MyAccountTrackShipping />
                <button
                  block="MyAccountOrderItemsTable"
                  elem="TrackShipmentButton"
                  onClick={ onTrackShipmentClick }
                >
                    { __('Track Shipment') }
                </button>
            </div>
        );
    }

    return callback.apply(context, args);
};

export const kemanaTrackShippingMyAccountOrderItemsTableOverrides = {
    'member-function': {
        renderOrderTitleAndActions
    }
};

export default kemanaTrackShippingMyAccountOrderItemsTableOverrides;
