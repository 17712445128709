import { SET_INSTA_FEED } from './InstaFeed.action';

/** @namespace KemanaInstaFeed/Store/InstaFeed/Reducer/getInitialState */
export const getInitialState = () => ({
    images: []
});

/** @namespace KemanaInstaFeed/Store/InstaFeed/Reducer/InstaFeedReducer */
export const InstaFeedReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
    case SET_INSTA_FEED:

        const { payload } = action;
        return {
            ...state,
            images: payload
        };

    default:
        return state;
    }
};

export default InstaFeedReducer;
