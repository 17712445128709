/** @namespace KemanaReturnOrders/Component/MyAcccountOrder/MyAccountOrder/Component/renderBaseInfo */
import Link from 'Component/Link/Link.component';
import { ORDER_REFUNDS } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { convertStringToDate } from 'Util/Manipulations';

import { formatReturnOrders } from '../../util/ReturnOrders';
import { CREATE_RETURN_URL } from '../MyAccount/MyAccount.config';
import { COMPLETED_STATUS, ORDER_RETURNS } from './MyAccountOrder.config';

/** @namespace KemanaReturnOrders/Component/MyAcccountOrder/MyAccountOrder/Component/renderBaseInfo */
export const renderBaseInfo = (args, callback, context) => {
    const {
        order: {
            order_date, increment_id, fe_status_label, status
        }
    } = context.props;

    return (
        <div block="MyAccountOrder" elem="BaseInfo">
            <h2 block="MyAccountOrder" elem="OrderId">
                { __('Order #%s', increment_id) }
            </h2>
            <span block="MyAccountOrder" elem="OrderStatus">
                { fe_status_label }
            </span>
            <div block="MyAccountOrder" elem="Reorder">{ context.renderReorderButton() }</div>
            <span block="MyAccountOrder" elem="CreationDate">
                { __('Created: ') }
                { convertStringToDate(order_date) }
            </span>
            { status === COMPLETED_STATUS && (
                <Link block="MyAccountOrder" elem="ReturnOrder" to={ `${CREATE_RETURN_URL}/${increment_id}` }>
                    { __('Return item') }
                </Link>
            ) }
        </div>
    );
};

/** @namespace KemanaReturnOrders/Component/MyAcccountOrder/MyAccountOrder/Component/tabMap */
export const tabMap = (originalMember, instance) => ({
    ...originalMember,
    [ORDER_RETURNS]: {
        tabName: ORDER_RETURNS,
        title: __('Return'),
        shouldTabRender: () => {
            const { order: { returns: { items = [] } = {} } } = instance.props;
            return items.length;
        },
        render: () => {
            const { order: { returns: { items } } } = instance.props;
            const { renderOrderItemsTable } = instance.renderMap;
            const returnItems = formatReturnOrders(items);
            return renderOrderItemsTable({ items: returnItems });
        }
    },
    [ORDER_REFUNDS]: {
        ...originalMember[ORDER_REFUNDS],
        render: () => {
            const { order: { increment_id: orderNumber, credit_memos = [] } } = instance.props;
            const orderCreditMemo = credit_memos.map((creditMemo) => ({
                ...creditMemo, orderNumber
            }));
            const { renderOrderItemsTable } = instance.renderMap;
            return orderCreditMemo.map(renderOrderItemsTable);
        }
    }
});

export const myAccountOrderComponentOverrides = {
    'member-function': { renderBaseInfo },
    'member-property': { tabMap }
};

export default myAccountOrderComponentOverrides;
