import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { MY_ACCOUNT_CASHBACK } from '../../type/Account.type';
import { MY_ACCOUNT_CASHBACK_ROUTE } from '../MyAccountCashbackPoints/MyAccountCashbackPoints.config';

// eslint-disable-next-line max-len
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

/** @namespace KemanaCashbackPoints/Component/Router/Component/SWITCH_ITEMS_TYPE */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const SWITCH_ITEMS_TYPE = (originalMembers) => [
    ...originalMembers,
    {
        // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        component: <Route path={ withStoreRegex(`${MY_ACCOUNT_CASHBACK_ROUTE}`) } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ACCOUNT_CASHBACK } /> } />,
        position: 93,
        name: MY_ACCOUNT_CASHBACK
    }
];

export default {
    'Component/Router/Component': {
        'member-property': { SWITCH_ITEMS_TYPE }
    }
};
