import KemanaEventsReducer from './KemanaEvents/KemanaEvents.reducer';

/** @namespace KemanaEvents/Store/Index/getStaticReducers */
export const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    KemanaEventsReducer
});

export const kemanaEventsStoreOverrides = {
    function: getStaticReducers
};
