import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

import { PAYMENT_LIST, PAYMENT_REDIRECT_TYPES, SUPPORTED_PAYMENTS } from './KemanaPaymentMethods.config';

const _getAllEasyExtraFields = () => new Field('metro_bank_merchant_data')
    .setAlias('extraFields')
    .addFieldList(['name', 'merchant_id']);

const _getExtraOrderFields = (code) => {
    const supportedCode = SUPPORTED_PAYMENTS?.[code];
    const redirect = supportedCode?.redirect;

    if (supportedCode?.enabled) {
        if (redirect === PAYMENT_REDIRECT_TYPES.URL) {
            return [`${code}_redirect_url`];
        } if (code === PAYMENT_LIST.METRO_BANK && redirect === PAYMENT_REDIRECT_TYPES.CUSTOM) {
            return [
                `${code}_redirect_url`,
                _getAllEasyExtraFields()
            ];
        }
    }

    return [];
};

const _getOrderFieldList = (code) => [
    'order_id',
    ..._getExtraOrderFields(code)
];

const _getOrderField = (code) => new Field('order')
    .addFieldList(
        _getOrderFieldList(code)
    );

const getPlaceOrderMutation = (args) => {
    const [guestCartId, code = false] = args;

    const mutation = new Field('s_placeOrder')
        .setAlias('placeOrder')
        .addField(_getOrderField(code));

    if (!isSignedIn()) {
        mutation.addArgument('guestCartId', 'String', guestCartId);
    }

    return mutation;
};

export default {
    'Query/Checkout/Query': {
        'member-function': {
            getPlaceOrderMutation
        }
    }
};
