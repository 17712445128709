import PropTypes from 'prop-types';

import { Notification as SourceNotification }
    from 'SourceComponent/Notification/Notification.component';
import { isSignedIn } from 'Util/Auth';
import { translate } from 'Util/Text/Text';

/** @namespace FullyBooked/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    static propTypes = {
        handleLogout: PropTypes.func.isRequired
    };

    render() {
        const { notification, id, handleLogout } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;
        if (msgText === 'The current customer isn\'t authorized.') {
            if (isSignedIn()) {
                handleLogout();
            }

            return null;
        }
        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">
                    { translate(msgText) }
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
