export * from 'SourceRoute/MyAccount/MyAccount.config';

export const GENDER_MAP_DEFAULT_KEY = 'DEFAULT';
export const GENDER_MAP_DEFAULT_VALUE = 'Other';

export const GENDER_MAP = {
    0: __('-- Select gender --'),
    3: 'Male',
    6: 'Female',
    9: GENDER_MAP_DEFAULT_VALUE,
    [GENDER_MAP_DEFAULT_KEY]: GENDER_MAP_DEFAULT_VALUE
};
