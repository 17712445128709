export const MY_ACCOUNT_CANCEL_ORDER_POPUP = 'MY_ACCOUNT_CANCEL_ORDER_POPUP';
export const MAX_COMMENT_LENGTH = 1000;
export const COMMENT_VALIDATION_MESSAGE = `Maximum ${MAX_COMMENT_LENGTH} characters are allowed in comment!`;
export const ORDER_PROCESSING_STATUS = 'Processing';

export const COMMENT_VALIDATION = {
    range: {
        max: MAX_COMMENT_LENGTH
    },
    customErrorMessages: {
        onRangeFailMax: COMMENT_VALIDATION_MESSAGE
    }
};

export const CANCEL_ORDER_FIELDS = {
    COMMENT: 'comment',
    REASON: 'reason',
    ITEM_QTY: 'qty',
    ITEM_SKU: 'sku'
};
