export const GET_RETURN_ORDER_LIST = 'GET_RETURN_ORDER_LIST';
export const SET_RETURN_ORDER_LOADING_STATUS = 'SET_RETURN_ORDER_LOADING_STATUS';
export const GET_RETURN_ORDER_DETAIL = 'GET_RETURN_ORDER_DETAIL';
export const SET_RETURN_ORDER_DETAIL_LOADING_STATUS = 'SET_RETURN_ORDER_DETAIL_LOADING_STATUS';
export const SET_NEW_COMMENT_IN_RETURN_DETAILS = 'SET_NEW_COMMENT_IN_RETURN_DETAILS';
export const GET_CREATE_RETURN_ORDER_DETAIL = 'GET_CREATE_RETURN_ORDER_DETAIL';
export const SET_CREATE_RETURN_ORDER_DETAIL_LOADING_STATUS = 'SET_CREATE_RETURN_ORDER_DETAIL_LOADING_STATUS';

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/getReturnOrderList */
export const getReturnOrderList = (orderList) => ({
    type: GET_RETURN_ORDER_LIST,
    orderList
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/setReturnOrderLoadingStatus */
export const setReturnOrderLoadingStatus = (loadingStatus) => ({
    type: SET_RETURN_ORDER_LOADING_STATUS,
    loadingStatus
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/getReturnOrderDetail */
export const getReturnOrderDetail = (returnOrderDetail) => ({
    type: GET_RETURN_ORDER_DETAIL,
    returnOrderDetail
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/setReturnOrderDetailLoadingStatus */
export const setReturnOrderDetailLoadingStatus = (isOrderDetailLoading) => ({
    type: SET_RETURN_ORDER_DETAIL_LOADING_STATUS,
    isOrderDetailLoading
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/setNewCommentsInReturnDetails */
export const setNewCommentsInReturnDetails = (comments) => ({
    type: SET_NEW_COMMENT_IN_RETURN_DETAILS,
    comments
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/getCreateReturnOrderDetail */
export const getCreateReturnOrderDetail = (createReturnOrderDetail) => ({
    type: GET_CREATE_RETURN_ORDER_DETAIL,
    createReturnOrderDetail
});

/** @namespace KemanaReturnOrders/Store/ReturnOrder/Action/setCreateReturnOrderDetailLoadingStatus */
export const setCreateReturnOrderDetailLoadingStatus = (isCreateOrderDetailLoading) => ({
    type: SET_CREATE_RETURN_ORDER_DETAIL_LOADING_STATUS,
    isCreateOrderDetailLoading
});
