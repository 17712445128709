import {
    CLEAR_CURRENT_BLOG, UPDATE_BLOG_CATEGORY_LIST, UPDATE_BLOG_DATE_SORT_LIST,
    UPDATE_BLOG_DETAILS,
    UPDATE_BLOG_PRODUCT_SKU_LIST,
    UPDATE_BLOG_TAG_LIST,
    UPDATE_RELATED_BLOG_STATE
} from './Blog.action';

/** @namespace BlogModule/Store/Blog/Reducer/updateBlogDetails */
export const updateBlogDetails = (action, state) => {
    const { blog = {} } = action;

    return { ...state, blog };
};

/** @namespace BlogModule/Store/Blog/Reducer/getInitialState */
export const getInitialState = () => ({
    blog: {},
    isRelatedBlogsLoaded: false,
    categoryList: [],
    tagList: [],
    dateSortList: [],
    productSkuList: []
});

/** @namespace BlogModule/Store/Blog/Reducer/BlogReducer */
export const BlogReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case CLEAR_CURRENT_BLOG:
        return { ...state, blog: {}, isRelatedBlogsLoaded: false };
    case UPDATE_BLOG_DETAILS:
        return updateBlogDetails(action, state);
    case UPDATE_RELATED_BLOG_STATE:
        const { newState: isRelatedBlogsLoaded } = action;
        return { ...state, isRelatedBlogsLoaded };
    case UPDATE_BLOG_CATEGORY_LIST:
        const { categoryList } = action;
        return { ...state, categoryList };
    case UPDATE_BLOG_TAG_LIST:
        const { tagList } = action;
        return { ...state, tagList };
    case UPDATE_BLOG_DATE_SORT_LIST:
        const { dateSortList } = action;
        return { ...state, dateSortList };
    case UPDATE_BLOG_PRODUCT_SKU_LIST:
        const { productSkuList } = action;
        return { ...state, productSkuList };
    default:
        return state;
    }
};

export default BlogReducer;
