import { SET_CUSTOM_NOTIFICATIONS } from './CustomNotifications.action';

/** @namespace FullyBooked/Store/CustomNotifications/Reducer/getInitialState */
export const getInitialState = () => ({
    messageList: []
});

/** @namespace FullyBooked/Store/CustomNotifications/Reducer/CustomNotificationsReducer */
export const CustomNotificationsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_CUSTOM_NOTIFICATIONS:
        const { payload } = action;

        return {
            ...state,
            messageList: payload
        };

    default:
        return state;
    }
};

export default CustomNotificationsReducer;
