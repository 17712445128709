/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Component/renderCancelOrderRow */

/** @namespace KemanaCancelOrders/Component/MyAccountOrderTableRow/Component/renderCancelOrderRow */

/**
 * Function must be created in Component/MyAccountOrderTableRow/Component
 */
export const renderCancelOrderRow = (args, callback, context) => {
    const {
        order: {
            increment_id
        },
        isCancelOrderEnabledForCustomer,
        onCancelClick,
        cancelOrderButtonLabel
    } = context.props;

    return (
        <div block="KeyValueTable" elem="Column">
            { isCancelOrderEnabledForCustomer && increment_id
                && (
                    <button
                      block="MyAccountOrderTableRow"
                      elem="CancelButton"
                      onClick={ onCancelClick }
                    >
                        { cancelOrderButtonLabel }
                    </button>
                ) }
        </div>
    );
};

export const kemanaCancelOrderMyAccountOrderTableRowComponentOverrides = {
    'member-function': { renderCancelOrderRow }
};
