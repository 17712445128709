import FIELD_TYPE from 'Component/Field/Field.config';

import KemanaPasswordField from '../component/KemanaPasswordField';

const renderPasswordToggle = (propsData) => {
    const {
        props: {
            isDisabled, setRef, events, attr
        }
    } = propsData;

    return (<KemanaPasswordField setRef={ setRef } attr={ attr } events={ events } isDisabled={ isDisabled } />);
};

const renderMap = (originalMember, props) => ({
    ...originalMember,
    [FIELD_TYPE.password]: () => renderPasswordToggle(props)
});

export const config = {
    'Component/Field/Component': {
        'member-property': {
            renderMap
        }
    }
};

export default config;
