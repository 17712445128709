import { fetchMutation } from 'Util/Request';

import ProductListQuery from '../query/kemanaProductViewReport.query';

const setProductView = (id) => {
    const query = ProductListQuery.getProductViewReportQuery(id);
    fetchMutation(query);
};

const componentDidUpdate = async (args, callback, instance) => {
    callback.apply(instance, args);
    const [prevProps] = args;
    const { product: { id: prevId } } = prevProps;
    const { product: { id } = {} } = instance.props;

    if (id !== prevId) {
        setProductView(id);
    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
