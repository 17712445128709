import { ORDER_REFUNDS } from 'Component/MyAccountOrder/MyAccountOrder.config';

export const colSpanCounts = {
    [ORDER_REFUNDS]: {
        colSpanPriceCount: '1',
        colSpanLabelCount: '4'
    }
};

export const colSpanCountsMobile = {
    [ORDER_REFUNDS]: {
        colSpanPriceCount: '2',
        colSpanLabelCount: '3'
    }
};
