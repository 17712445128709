import { lazy } from 'react';

import { MY_ACCOUNT_DISCOUNT_CARD } from '../../type/Account.type';

export const MyAccountCashbackPoints = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-discount-card" */
    '../../component/DiscountCard'
));

/** @namespace KemanaDiscountCard/Route/MyAccount/Component/renderMap */
export const renderMap = (prevRenderMap) => ({
    ...prevRenderMap,
    [MY_ACCOUNT_DISCOUNT_CARD]: MyAccountCashbackPoints
});

export default { 'Route/MyAccount/Component': { 'member-property': { renderMap } } };
