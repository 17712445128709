export default {
    init(id) {
        (function (w, d, s, l, i) {
            // eslint-disable-next-line no-param-reassign
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(),
                event: 'gtm.js'
            }); const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s); const
                // eslint-disable-next-line eqeqeq
                dl = l != 'dataLayer' ? `&l=${ l}` : '';

            j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${ i }${dl}`; f.parentNode.insertBefore(j, f);
        }(window, document, 'script', 'dataLayer', id));
    },

    installPromptCounter() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'installModule',
            action: 'add'
        });
    },

    purchaseDataAnalytics(cartData, orderId) {
        const {
            quote_currency_code, tax_amount, subtotal, shipping_amount, coupon_code, items
        } = cartData;

        const itemsData = items.map(({
            price, sku, product: { name }, discount_amount, qty
        }) => ({
            item_id: sku,
            item_name: name,
            discount: discount_amount,
            price,
            quantity: qty
        }));

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push('event', 'purchase', {
            transaction_id: orderId,
            value: subtotal,
            tax: tax_amount,
            shipping: shipping_amount,
            currency: quote_currency_code,
            coupon: coupon_code,
            items: itemsData
        });
    }
};
