export const SHIPPING_ADDRESS = 'SHIPPING_ADDRESS';
export const SHIPPING_ADDRESS_KEYS = ['firstname', 'lastname', 'street', 'city', 'telephone'];
export const MAX_COMMENT_LENGTH = 1000;
export const COMMENT_VALIDATION_MESSAGE = `Maximum ${MAX_COMMENT_LENGTH} characters are allowed in comment!`;

export const COMMENT_VALIDATION = {
    range: {
        max: MAX_COMMENT_LENGTH
    },
    customErrorMessages: {
        onRangeFailMax: COMMENT_VALIDATION_MESSAGE
    }
};

export const RETURN_CONDITION_LABEL = 'Item Condition';
export const RETURN_RESOLUTION_LABEL = 'Resolution';
export const RETURN_OTHER_LABEL = 'Others(please specify)';
export const RETURN_REASON_TO_RETURN_LABEL = 'Reason to Return';
