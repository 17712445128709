import { MinusIcon as SourceMinusIcon } from 'SourceComponent/MinusIcon/MinusIcon.component';

/** @namespace FullyBooked/Component/MinusIcon/Component */
export class MinusIconComponent extends SourceMinusIcon {
    render() {
        return (
            <div />
        );
    }
}

export default MinusIconComponent;
