/** @namespace KemanaCancelOrders/Query/Order/Query/_getOrderItemsFields */
export const _getOrderItemsFields = (args, callback) => {
    const previousValues = callback(...args);
    return [
        ...previousValues,
        'is_cancelable'
    ];
};

export const kamanaCancelOrdersOrderQueryOverrides = {
    'member-function': {
        _getOrderItemsFields
    }
};
