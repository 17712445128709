import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { MY_ACCOUNT_TRACK_SHIPPING_POPUP } from './MyAccountTrackShipment.config';

import './MyAccountTrackShipping.style';

/** @namespace KemanaTrackShipping/Component/MyAccountTrackShipping/Component */
export class MyAccountTrackShippingComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        shipmentNumber: PropTypes.string.isRequired,
        shipmentMessage: PropTypes.string.isRequired,
        shipmentSteps: PropTypes.arrayOf(PropTypes.shape({
            status: PropTypes.string.isRequired,
            date_time: PropTypes.string.isRequired
        })).isRequired
    };

    renderShipmentSteps() {
        const { shipmentSteps } = this.props;
        if (!shipmentSteps.length) {
            return null;
        }

        return (
            <div block="MyAccountTrackShipping" elem="StepsContainer">
                { shipmentSteps.map(({ status, date_time, key }, index) => (
                    <div block="MyAccountTrackShipping" elem="StepContainer" key={ key }>
                        <div block="MyAccountTrackShipping" elem="LeftContent">
                            <div block="MyAccountTrackShipping" elem="Pointer" />
                            { (shipmentSteps.length - 1) !== index
                                && <div block="MyAccountTrackShipping" elem="SideLine" /> }
                        </div>
                        <div block="MyAccountTrackShipping" elem="RightContent">
                            <div block="MyAccountTrackShipping" elem="Status">
                                { status }
                            </div>
                            <div block="MyAccountTrackShipping" elem="Date">
                                { date_time }
                            </div>
                        </div>
                    </div>
                )) }
            </div>
        );
    }

    renderShipmentMessage() {
        const { shipmentMessage, shipmentSteps } = this.props;
        if (shipmentSteps.length) {
            return null;
        }

        return (
            <div block="MyAccountTrackShipping" elem="ShipmentMessage">
                <div block="MyAccountTrackShipping" elem="Pointer" />
                <div block="MyAccountTrackShipping" elem="ShipmentMessageContent">
                    { shipmentMessage }
                </div>
            </div>
        );
    }

    renderSubTitle() {
        const { shipmentNumber } = this.props;
        return (
            <div>
                <h4 block="hidden-desktop">
                    { __('Track Shipment') }
                </h4>
                <h6 block="MyAccountTrackShipping" elem="Subtitle">
                    { __('Shipment #%s', shipmentNumber) }
                </h6>
            </div>
        );
    }

    renderContent() {
        const { isLoading } = this.props;
        if (isLoading) {
            return null;
        }

        return (
            <>
                { this.renderSubTitle() }
                { this.renderShipmentMessage() }
                { this.renderShipmentSteps() }
            </>
        );
    }

    renderPopupContent() {
        const { isLoading } = this.props;
        return (
            <div block="MyAccountTrackShipping" elem="Container">
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }

    render() {
        return (
                <Popup
                  mix={ { block: 'MyAccountTrackShipping' } }
                  id={ MY_ACCOUNT_TRACK_SHIPPING_POPUP }
                  clickOutside
                >
                    { this.renderPopupContent() }
                </Popup>
        );
    }
}

export default MyAccountTrackShippingComponent;
